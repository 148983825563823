import {
    FETCH_ADMINS,
    FETCH_ADMINS_SUCCESS,
    FETCH_ADMINS_FAILED,
  
  } from "../store/types";


export const fethAdmins = () => async (dispatch) => {
    try {
      const authToken = document.cookie
        .split("; ")
        .find((row) => row.startsWith("authToken="))
        ?.split("=")[1];
  
      dispatch({
        type: FETCH_ADMINS,
        payload: authToken,
      });
  
  
      if (authToken) {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_API}/user/getAllAdmins`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        const programs = await response.json();
        dispatch({ type: FETCH_ADMINS_SUCCESS, payload: programs });
        
      } else {
        dispatch({
          type: FETCH_ADMINS_FAILED,
          payload: null ,
        });
      }
    } catch (error) {
      dispatch({ type: FETCH_ADMINS_FAILED, payload: { error: "Error occured" } });
    }
  };
  

export const UpdateAdmins =(adminData)=>async(dispatch)=>{
  const authToken = document.cookie
  .split("; ")
  .find((row) => row.startsWith("authToken="))
  ?.split("=")[1];
  
  return new Promise(async (resolve, reject) => {
      try {
           
        const {password,email} = adminData
          if (authToken) {
            const response = await fetch(
              `${process.env.REACT_APP_BACKEND_API}/user/updateAdminProfile`,
              {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${authToken}`,
                },
                body: JSON.stringify({
                  email,password                
                }),
              }
            );
            const admin = await response.json();
            if(admin.msg){
               resolve({msg:"Admin Profile Successfully Updated"})
            }
            else{
              reject({error:admin?.error||"Erro occured, try again later"})
            }
            
            
          } else {
            reject({ error: "Un authenticated user" });
          }
        } catch (error) {
          reject({ error: "Error occured, please try again" });
        }
      })


}

export const addAdmin =(adminData)=>async(dispatch)=>{
  const authToken = document.cookie
  .split("; ")
  .find((row) => row.startsWith("authToken="))
  ?.split("=")[1];
  
  return new Promise(async (resolve, reject) => {
      try {
           
        const {password,email,phone,halfAdminPrivileges} = adminData
          if (authToken) {
            const response = await fetch(
              `${process.env.REACT_APP_BACKEND_API}/user/addAdmin`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${authToken}`,
                },
                body: JSON.stringify({
                  email, password,
                  phone, halfAdminPrivileges        
                }),
              }
            );
            const admin = await response.json();
            if(admin.msg){
               resolve({msg:"Admin Successfully Added"})
            }
            else{
              reject({error:admin?.error||"Erro occured, try again later"})
            }
            
            
          } else {
            reject({ error: "Un authenticated user" });
          }
        } catch (error) {
          reject({ error: "Error occured, please try again" });
        }
      })


}

export const editAdmin =(adminData)=>async(dispatch)=>{
  const authToken = document.cookie
  .split("; ")
  .find((row) => row.startsWith("authToken="))
  ?.split("=")[1];
  
  return new Promise(async (resolve, reject) => {
      try {
           
        const {password,halfAdminPrivileges,adminId} = adminData
          if (authToken) {
            const response = await fetch(
              `${process.env.REACT_APP_BACKEND_API}/user/editAdmin`,
              {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${authToken}`,
                },
                body: JSON.stringify({
                   password,
                  halfAdminPrivileges,
                  adminId    
                }),
              }
            );
            const admin = await response.json();
            if(admin.msg){
               resolve({msg:"Admin Successfully Updated"})
            }
            else{
              reject({error:admin?.error||"Erro occured, try again later"})
            }
            
            
          } else {
            reject({ error: "Un authenticated user" });
          }
        } catch (error) {
          reject({ error: "Error occured, please try again" });
        }
      })


}

export const deleteAdmin =(adminId)=>async(dispatch)=>{
  const authToken = document.cookie
  .split("; ")
  .find((row) => row.startsWith("authToken="))
  ?.split("=")[1];
  
  return new Promise(async (resolve, reject) => {
      try {
           
          if (authToken) {
            const response = await fetch(
              `${process.env.REACT_APP_BACKEND_API}/user/deleteAdmin`,
              {
                method: "DELETE",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${authToken}`,
                },
                body: JSON.stringify({
                  adminId    
                }),
              }
            );
            const admin = await response.json();
            if(admin.msg){
               resolve({msg:"Admin Successfully Deleted"})
            }
            else{
              reject({error:admin?.error||"Erro occured, try again later"})
            }
            
            
          } else {
            reject({ error: "Un authenticated user" });
          }
        } catch (error) {
          reject({ error: "Error occured, please try again" });
        }
      })


}