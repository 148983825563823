import React, { useEffect, useRef, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Home from "./pages/Home";
import Setting from "./pages/Setting";
import PageNotFound from "./pages/PageNotFound";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/signUp";
import Announcement from './pages/Announcement'
import Admin from './pages/Admin'
import  Messages from "./pages/Messages"

import "./assets/css/style.css";
import { fetchUser } from "./common/action/authAction";
import { useSelector, useDispatch } from "react-redux";
import { CircularProgress, useTheme } from "@mui/material";
import useCustomStyles from "./components/useCustomStyles";
import Programs from "./pages/Programs";

import { fethPrograms } from "./common/action/programAction";
import { fethActivities } from "./common/action/activityAction";
import { fethAdmins } from "./common/action/adminsAction";

import CustomProgress from "./components/CustomProgress";
import { fethAnnouncements } from "./common/action/announcementAction";
import Registrations from "./pages/Registrations";
import { fetchSettings } from "./common/action/settingaction";
import { fethRegistrations } from "./common/action/registrationaction";
import { fethMessages } from "./common/action/meessageActions";

const styles = (theme) => ({
  loadingContainer: {},
});
export default function App() {
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);
  const auth = useSelector((state) => state.auth);

  useEffect(()=>{
        if(auth?.info?._id){
          dispatch(fethAnnouncements());
          dispatch(fethPrograms());
          dispatch(fethActivities());
          dispatch(fethAdmins());
          dispatch(fetchSettings());
          dispatch(fethRegistrations());
          dispatch(fethMessages());

        }
  },[auth?.info])

  const [previledges,setPreviledges] = useState({all:false})
  useEffect(()=>{
      if(auth?.info?.admin?.adminType === 'full'){
        setPreviledges({full:true})
      }
      else {
            setPreviledges({...auth?.info?.admin?.halfAdminPrivileges})
      }

  },[auth])

  return auth.loading ? (
  <CustomProgress/>
  ) : (
    <BrowserRouter>
      <Routes>
        {auth?.info?._id ? (
          <>
            <Route path="/" element={<Home previledges={previledges}/>} />
           {(previledges?.full||previledges?.managePrograms)&&<Route path="/programs" element={<Programs  previledges={previledges}  />} />}
           {(previledges?.full||previledges?.manageAnnouncements)&&<Route path="/announcement" element={<Announcement  previledges={previledges} />} />}
            <Route path="/setting" element={<Setting previledges={previledges}  />} />
            {previledges?.full &&<Route path="/messages" element={<Messages previledges={previledges}  />} />}
           {previledges?.full && <Route path="/admins" element={<Admin previledges={previledges}   />} />}
           {(previledges?.full||previledges?.manageRegistrations) && <Route path="/registrations" element={<Registrations previledges={previledges} />} />}
            
          </>
        ) : (
          <>
            <Route path="/logIn" element={<SignIn />} />
            <Route path="/signUp" element={<SignUp />} />
          </>
        )}

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
