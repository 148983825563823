import { Box, Typography,Button,
    ListItemIcon,
    MenuItem,
    Modal,
    InputAdornment,
    IconButton,
    Select,
    FormControl,
    InputLabel,
    Alert,
    TextField,
    CircularProgress,
     } from '@mui/material'
  import {  DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
  import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
  import React,{ useEffect, useMemo, useState } from 'react';
  import CustomInput from "../components/CustomInput"
  import {
    MRT_GlobalFilterTextField,
    MRT_ToggleFiltersButton,
    MaterialReactTable,
    useMaterialReactTable,
  } from 'material-react-table';
  import BackpackIcon from "@mui/icons-material/Backpack";
  import AddIcon from '@mui/icons-material/Add';
  import EditIcon from '@mui/icons-material/Edit';
  import DeleteIcon from '@mui/icons-material/Delete';
  import { TimePicker } from '@mui/x-date-pickers/TimePicker';
  import { v4 as uuidv4 } from 'uuid';
  import { useDispatch, useSelector } from "react-redux";
  import { fethAnnouncements, createAnnouncements, updateAnnouncements, deleteAnnounecement } from '../common/action/announcementAction';
  import CustomProgrss from '../components/CustomProgress';
  import moment from 'moment';
  import { message } from "antd";
  import dayjs from 'dayjs';
  import utc from 'dayjs/plugin/utc';
  import customParseFormat from 'dayjs/plugin/customParseFormat';
  import CommonSection from '../components/commonSection';
  import Header from '../components/Header/Header';
  import PhotoCamera from '@mui/icons-material/PhotoCamera'; // Importing camera icon
  import Theme from '../assets/css/theme';
import { AssignmentReturn } from '@mui/icons-material';

  dayjs.extend(utc);
  dayjs.extend(customParseFormat);
  
  
  export default function Announcements() {
    const dispatch = useDispatch()
    const announcements = useSelector(state=>state.announcements)

    useEffect(()=>{
      dispatch(fethAnnouncements())
    },[dispatch,fethAnnouncements])
    const [data,setData] =useState([]);
   
    useEffect(()=>{
          if(announcements?.announcements){
            if(announcements?.announcements.length===0){
              setData([])
            }
            else{
              setData(announcements?.announcements)
            }
          }
    },[announcements])
  
    return (
      <Box sx={sxStyles.container}>
          <CommonSection />
          <Header />
          <Box sx={sxStyles.content}>
            <Typography variant='h4' sx={sxStyles.typography}>Announcements</Typography>
            {announcements.loading ? 
              <Box sx={sxStyles.progressIndicator}>
                    <CircularProgress size={60}/>
              </Box>
             :
              <Box sx={{marginTop:"20px"}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <AllAnnouncements data={data} setData={setData} />
                </LocalizationProvider>
              </Box>
             }
  
          </Box>
      </Box>
    )
  }
  
    
  
  const AllAnnouncements = ({data,setData}) => {
  
    const dispatch = useDispatch()
  
    function getUniqueObjects(arr) {
      const uniqueMap = new Map();
      for (const obj of arr) {
        if (!uniqueMap.has(obj.day)) {
          uniqueMap.set(obj.day, obj);
        }
      }
        return Array.from(uniqueMap.values());
    }
  
    const columns = useMemo(
      () => [
            {
              accessorFn: (row) => `${row.name}`, //accessorFn used to join multiple data into a single cell
              id: 'name', //id is still required when using accessorFn instead of accessorKey
              header: 'Name',
              enableClickToCopy: true,
              size: 250,
              Cell: ({ renderedCellValue, row }) => {
  
                //console.log(row?.original, `${process.env.REACT_APP_BACKEND_API}/${row.original?.coverPicture}`);
                return   <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem',
                }}
              >
                <Box>{renderedCellValue}</Box>
              </Box>
              },
            },  
            {
              accessorFn: (row) => `${row.description}`, //accessorFn used to join multiple data into a single cell
              id: 'description', //id is still required when using accessorFn instead of accessorKey
              header: 'Description',
              enableClickToCopy: true,
              size: 250,
              Cell: ({ renderedCellValue, row }) => (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1rem',
                  }}
                >
                  <Box>{renderedCellValue}</Box>
                </Box>
              ),
            },
            {
              accessorKey: 'startingDate',
              //enableClickToCopy: true,
              //filterVariant: 'autocomplete',
              header: 'Starting Date',
              size: 300,
              Cell: ({ renderedCellValue, row }) => (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1rem',
                  }}
                >
                   
                  <Box>{moment(renderedCellValue).format('LLL')}</Box>
                </Box>
              ),
            },
            {
              accessorKey: 'endDate',
              header: 'End Date',
              size: 300,
              Cell: ({ renderedCellValue, row }) => (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1rem',
                  }}
                >
                   
                  <Box>{moment(renderedCellValue).format('LLL')}</Box>
                </Box>
              ),
            },
            {
              accessorKey: 'isActive', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
              header: 'Status',
              size: 200,
              Cell: ({ cell }) => (
                  <Box
                    component="span"
                    sx={(theme) => ({
                      color:cell.getValue()?'#9aeb96':'red',
                      fontWeight:"600"
                    })}
                  >
                  {cell.getValue() ? 'Active' : "Deactive"}
                  </Box>
                ),
            },
            {
              accessorKey: 'createdBy', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
              //filterVariant: 'autocomplete',
              header: 'Created By',
              size: 300,
              Cell: ({ renderedCellValue, row }) => (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '1rem',
                    }}
                  >
                     {row.original?.createdBy?.email||'Admin'}
                  </Box>
                ),
            }, 
            {
              accessorKey: 'createdAt',
              //enableClickToCopy: true,
              //filterVariant: 'autocomplete',
              header: 'Created At',
              size: 300,
              Cell: ({ renderedCellValue, row }) => (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1rem',
                  }}
                >
                   
                  <Box>{moment(renderedCellValue).format('lll')}</Box>
                </Box>
              ),
            }, 
         
      ],
      [],
    );
  
    const [selectedRow, setSelectedRow] = useState(null);
    const [openEdit, setOpenEdit]= useState(false)
    const [openAddModal, setOpenAddModal]= useState(false)
    const [newData, setNewData] = React.useState({
      name: "",
      file: "",
      description:"",
      startingDate: dayjs(),
      endDate: dayjs(),
      imageUrl: "",
    });

  
  const onImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setNewData({ 
        ...newData, 
        file: file,
        imageUrl: URL.createObjectURL(file) // Store the image URL
      });
    }
  };;
  
    const handleclose=()=>{
      setOpenEdit(false)
      setSelectedRow(null)
      setNewData({
        name: "",
        file: "",
        classType:"",
        startingDate: dayjs(),
        imageUrl: "",
      })
      setOpenAddModal(false)
    }
  
    const onEditRow=(row,closeMenu)=>{
      setSelectedRow(row.original);
      setNewData({
           announcementId: row.original?._id,
           name: row.original.name,
           file:row.original.coverPicture,
           description:row.original.description,
           startingDate:dayjs(row.original.startingDate),
           endDate:dayjs(row.original.endDate),
           imageUrl:`${process.env.REACT_APP_BACKEND_API}${row.original?.coverPicture}`})
      setOpenAddModal(true)
      setOpenEdit(true)
      closeMenu()
    }
  
    const onDeleteRow=(row,closeMenu)=>{
      const updatedData = data.filter(obj => obj !== row.original);
      dispatch(deleteAnnounecement(row.original._id))
      closeMenu()
      
    }
    
  
    const table = useMaterialReactTable({
      columns,
      data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
      enableColumnFilterModes: true,
      enableColumnOrdering: true,
      enableGrouping: true,
      enableColumnPinning: true,
      enableFacetedValues: true,
      enableRowActions: true,
      initialState: {
        showColumnFilters: false,
        showGlobalFilter: true,
        columnPinning: {
          left: ['mrt-row-expand', 'mrt-row-select'],
          right: ['mrt-row-actions'],
        },
      },
      mrtTheme: (theme) => ({
        baseBackgroundColor: 'rgba(255, 255, 255, 0.01)', // Light background for overall table
      }),
      muiTableHeadCellProps: {
        sx: {
          background: `linear-gradient(135deg, #9e9d9e 0%, #f4f0f5 100%)`, // Vibrant gradient for header
          color: 'black', // White text for contrast
          fontWeight: 'bold', // Make the header text bold
          fontSize: '1rem', // Slightly larger font for visibility
        },
      },
      muiTableBodyCellProps: {
        sx: {
          background: 'rgba(255, 255, 255, 0.05)', // Subtle white overlay for table body
          color: '#d3d3d3', // Light grey for text
          borderBottom: '1px solid rgba(255, 255, 255, 0.1)', // Faint border for rows
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)', // Slight hover effect
          },
        },
      },
      muiTableBodyRowProps: {
        sx: {
          '&:nth-of-type(odd)': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)', // Slight background change for alternating rows
          },
        },
      },
      muiPaginationProps: {
        color: 'secondary', // Secondary theme color for pagination controls
        rowsPerPageOptions: [10, 20, 30],
        shape: 'rounded',
        variant: 'outlined',
        sx: {
          background: 'rgba(0, 0, 0, 0.1)', // Slight background to make pagination stand out
        },
      },
      muiSearchTextFieldProps: {
        size: 'small',
        variant: 'outlined',
        color: 'secondary', // Secondary color for the search field
        sx: {
          background: 'rgba(255, 255, 255, 0.6)', // Transparent background to blend with the table
        },
      },
      renderDetailPanel: ({ row }) => (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-around',
            left: '30px',
            maxWidth: '1000px',
            position: 'sticky',
            width: '100%',
            color:"white"
          }}
        >
          <img
            alt="avatar"
            height={150}
            src={`${process.env.REACT_APP_BACKEND_API}${row.original?.coverPicture}`}
            loading="lazy"
          /> 
          
        </Box>
      ),
      renderRowActionMenuItems: ({ row, closeMenu }) => [
          <MenuItem
            key={0}
            onClick={() =>onEditRow(row,closeMenu)}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
          </MenuItem>,
        
          <MenuItem
            key={1}
            onClick={() =>onDeleteRow(row,closeMenu)}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <DeleteIcon style={{ color: 'red' }} />
            </ListItemIcon>
          </MenuItem>,
        ],
      renderTopToolbar: ({ table }) => {
        const handleDeactivate = () => {
          setOpenAddModal(true)
        };   
        return (
          <Box
            sx={(theme) => ({
               display: 'flex',
              gap: '0.5rem',
              p: '8px',
              justifyContent: 'space-between',
            })}
          >
            <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
              {/* import MRT sub-components */}
              <MRT_GlobalFilterTextField table={table} />
              <MRT_ToggleFiltersButton table={table} />
            </Box>
            <Box sx={{ display: 'flex', gap: '0.5rem' }}>
              <Button
                sx={sxStyles.button}
                onClick={handleDeactivate}
                variant="contained"
              >
                Add
              </Button>
            </Box>
          </Box>
        );
      },
    });
  
      
    const [error, setError] = useState("")
    const onInputChange = (event) => {
      setNewData({ ...newData, [event.target.id]: event.target.value });
    };
  
    const [errorMessage,setErrorMessage]=message.useMessage();
    const [messageApi, contextHolder] = message.useMessage();
    //successfull message
    const success = (message) => {
      messageApi.open({
        type: 'success',
        content: message,
        duration: 3,
      });
    };
    
    //errror message
    const Error = (message) => {
     errorMessage.open({
       type: 'error',
       content: message,
       duration: 2,
     });
   
    };
  
    const handleSave=()=>{
      if(!newData.name || !newData.description  || !newData.file || !newData.startingDate || !newData.endDate ){
        setError("Please Fill All Necessery Fields")
        return
      }
       let dataToBeAdded = {...newData}
       if(openEdit){
        dispatch(updateAnnouncements(dataToBeAdded))
  
       }
       else{
         dispatch(createAnnouncements(dataToBeAdded))
       }
  
    }
  
  
  
    return (
     <div>
       {setErrorMessage}
       {contextHolder}
  
       <MaterialReactTable table={table} />
  
      {/**modal for adding and update*/}
      <Modal
            open={openAddModal}
            onClose={handleclose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
          <Box  sx={sxStyles.modal}>
                <Typography sx={{textAlign:"center",marginBottom:10}}>{!openEdit?"Add New":"Update"} Announcements</Typography>
  
                    <Box sx={sxStyles.eachInput} display="flex" justifyContent="center" alignItems="center">
                        <input
                          accept="image/*"
                          style={{ display: 'none' }}
                          id="upload-image"
                          type="file"
                          onChange={onImageChange}
                        />
                        <label htmlFor="upload-image" style={{ position: 'relative', display: 'inline-block' }}>
                          {newData.imageUrl && (
                            <img 
                              src={newData.imageUrl} 
                              alt="Selected" 
                              style={{ maxHeight: '200px', maxWidth: '100%', display: 'block' }} 
                            />
                          )}
                          <IconButton 
                            component="span" 
                            sx={{
                              backgroundColor: '#3c5969',
                              borderRadius: '50%',
                              padding: '10px',
                              boxShadow: '0 2px 4px rgba(0,0,0,1)',
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)'
                            }}>
                            <PhotoCamera style={{color:"wheat"}} />
                          </IconButton>
                        </label>
                    </Box>
  
                    <Box  sx={sxStyles.eachInput}>
                        <CustomInput
                          id="name"
                          labelText="Name"
                          labelColor="rgba(255, 255, 255, 0.7)"
                          value={newData.name}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: "text",
                          }}
                          color="white"
                          onChange={onInputChange}
                        />
                    </Box>

                    <Box  sx={sxStyles.eachInput}>
                        <CustomInput
                          id="description"
                          labelText="Description"
                          labelColor="rgba(255, 255, 255, 0.7)"
                          value={newData.description}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: "text",
                          }}
                          color="white"
                          onChange={onInputChange}
                        />
                    </Box>
  
  
  
                    <Box sx={sxStyles.eachInput}>
                      <Typography sx={{marginBottom:1,color:'rgba(255, 255, 255, 0.7)'}}>Starting Date</Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            value={newData.startingDate}
                            onChange={(newValue) =>setNewData({ ...newData, startingDate: newValue })}
                            renderInput={(params) => <TextField {...params} />}
                            // disabled={openEdit}
                            sx={{width:"100%"}}
                          />
                        </LocalizationProvider>
                    </Box>

                    <Box sx={sxStyles.eachInput}>
                      <Typography sx={{marginBottom:1,color:'rgba(255, 255, 255, 0.7)'}}>End Date</Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            value={newData.endDate}
                            onChange={(newValue) =>setNewData({ ...newData, endDate: newValue })}
                            renderInput={(params) => <TextField {...params} />}
                            // disabled={openEdit}
                            sx={{width:"100%"}}
                          />
                        </LocalizationProvider>
                    </Box>
                  
  
                    <Box sx={{textAlign:"center",marginTop:"10px",marginBottom:3,width:"100%"}}>
                        <Button onClick={handleSave} sx={{...sxStyles?.button,...sxStyles.eachInput}}>Save</Button>
                    </Box>
  
                    {error && <Alert style={{width:'60%'}} severity="error">{error}</Alert>}
  
          </Box>
          
      </Modal>
      
     </div>
  );
  };
  
  
  const sxStyles={
    container:{
      background: `linear-gradient(-20deg,${Theme.COLOR_THEME_TWO} 0%, ${Theme.COLOR_THEME_ONE} 100%)`,
      minHeight: "90vh",
      padding: "120px 40px 30px 40px",
    },
    progressIndicator:{
      height:"50vh",
      display:'flex',
      justifyContent:"center",
      alignItems:'center',
},
    content: {
      position: "relative",
      top: "0px",
      width: "100%",
      zIndex: 2,
      color: 'white'
    },
    typography:{
      padding:"10px 0 0 20px"
    }, 
    modal:{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width:"60%",
      bgcolor: 'rgba(62, 64, 62)',
      border: '2px solid #000',
      borderRadius:"20px",
      boxShadow: 24,
      p: 4,
      color:'white',
      zIndex:10,
      height: "60vh",
      overflow:"auto",
      color: "white",
      "::-webkit-scrollbar": {
        width: "1px",
      },
      display:"flex",
      flexDirection:'column',
      alignItems:"center",
      '@media(max-width:800px)':{
        width:"80%",
      },
    },
    eachInput:{
      marginBottom:2,
      width:'60%',
      '@media(max-width:800px)':{
        width:"80%",
      },
      
    },
    button:{
      backgroundColor: "#371238",
      color: "white",
      textTransform: "inherit",
      "&:hover": {
        background: "#371238",
        //background: "#00bab4",
      },
    }
  }