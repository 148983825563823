import {
    FETCH_ANNOUNCEMENTS,
    FETCH_ANNOUNCEMENTS_SUCCESS,
    FETCH_ANNOUNCEMENTS_FAILED,
  
    CREATE_ANNOUNCEMENTS,
    CREATE_ANNOUNCEMENTS_SUCCESS,
    CREATE_ANNOUNCEMENTS_FAILED,
  
  
  } from "../store/types";
  
  const INITIAL_STATE = {
    info: null,
    loading: true,
    error: null,
    announcements: null,
  };
  
  export const AnnouncementReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_ANNOUNCEMENTS:
        return {
          ...state,
          loading: true,
        };
      case FETCH_ANNOUNCEMENTS_SUCCESS:
        return {
          ...state,
          announcements: action.payload,
          loading: false,
          error: null,
          info: null,
        };
      case FETCH_ANNOUNCEMENTS_FAILED:
        return {
          ...state,
          announcements: null,
          info: null,
          loading: false,
          error: action.payload,
        };
  
      case CREATE_ANNOUNCEMENTS:
          return {
              ...state,
              loading: true
          };
      case CREATE_ANNOUNCEMENTS_FAILED:
          return {
              ...state,
              info: null,
              loading: false,
              error: action.payload,
          };
      case CREATE_ANNOUNCEMENTS_SUCCESS:
          return {
              ...state,
              info: action.payload,
              loading: false,
              error: null,
          };
  
      default:
        return state;
    }
  };
   