import {
    FETCH_REGISTRATIONS,
    FETCH_REGISTRATIONS_SUCCESS,
    FETCH_REGISTRATIONS_FAILED,
  
  } from "../store/types";
  
  const INITIAL_STATE = {
    info: null,
    loading: true,
    error: null,
    registrations: null,
  };
  
  export const registrationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_REGISTRATIONS:
        return {
          ...state,
          loading: true,
        };
      case FETCH_REGISTRATIONS_SUCCESS:
        return {
          ...state,
          registrations: action.payload,
          loading: false,
          error: null,
          info: null,
        };
      case FETCH_REGISTRATIONS_FAILED:
        return {
          ...state,
          registrations: null,
          info: null,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  