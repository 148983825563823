import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Grid, Typography, Card, CardContent, LinearProgress } from "@mui/material";
import CommonSection from "../components/commonSection";
import Header from "../components/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import Theme from "../assets/css/theme";
import { textAlign, width } from "@mui/system";
import moment from "moment"
import {translateText} from  "../components/translator"

export default function Home({previledges}) {
  
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const activities = useSelector((state) => state.activities);
  const programs = useSelector((state) => state.programs);
  const announcements = useSelector((state) => state.announcements);
  const admins = useSelector((state) => state.admins);
  const registration = useSelector((state) => state.registration);
  const messages = useSelector((state) => state.messages);


  // Placeholder data for demonstration
  const [summary, setSummary] = useState({
    programs: 0,
    upcomingPrograms: 0,
    announcements: 0,
    transactions: 0,
    admins: 0,
    messages:0
  });
  
  useEffect(() => {
    if (messages?.messages && messages.messages.length > 0) {
        
      const newMessages = messages.messages.filter(e => {
        return !e.isReplied;
      });
  
      setSummary(prevSummary => ({
        ...prevSummary,
        messages: newMessages.length,
      }));
    }
  }, [messages]);

  useEffect(() => {
    if (programs?.programs && programs.programs.length > 0) {
        
      const today = new Date().getTime();
      const activePrograms = programs.programs.filter(e => {
        const programDate = new Date(e.startingDate).getTime();
        return programDate <= today;
      });
  
      const upcomingPrograms = programs.programs.filter(e => {
        const programDate = new Date(e.tartingDate).getTime();
        return programDate > today;
      });
  
      setSummary(prevSummary => ({
        ...prevSummary,
        programs: activePrograms.length,
        upcomingPrograms: upcomingPrograms.length,
      }));
    }
  }, [programs]);
  
  useEffect(() => {
    if (admins?.admins && admins.admins.length > 0) {
      setSummary(prevSummary => ({
        ...prevSummary,
        admins: admins.admins.length,
      }));
    }
  }, [admins]);

  useEffect(() => {
    if (announcements?.announcements && announcements.announcements.length > 0) {
      setSummary(prevSummary => ({
        ...prevSummary,
        announcements: announcements?.announcements?.length,
      }));
    }
  }, [announcements]);

  useEffect(() => {
    if (registration?.registrations && registration.registrations.length > 0) {
      setSummary(prevSummary => ({
        ...prevSummary,
        registrations: registration?.registrations?.length,
      }));
    }
  }, [announcements]);
  
  

  const [recentActivity, setRecentActivity] = useState([]);
  useEffect(() => {
    if (activities?.activities && activities.activities.length > 0) {
        setRecentActivity(activities.activities)
    }
  }, [activities]);

  
  return (
    <Box sx={styles.container}>
      <CommonSection />
      <Header />

      <Box sx={styles.content}>
        <Typography variant="h4" sx={styles.welcomeMessage}>
          Welcome, {auth.info?.username}!
        </Typography>
        <Box sx={styles.cards}>
          <Grid sx={styles.cardsContainer} container spacing={2}>
            {/* Summary Statistics */}
            {(previledges?.full||previledges?.managePrograms)&&<Grid item xs={12} md={6} lg={3}>
              <Card sx={styles.card}>
                <CardContent>
                  <Typography variant="h6">Active Programs</Typography>
                  <Typography variant="h4">{summary.programs}</Typography>
                </CardContent>
              </Card>
            </Grid>}

           {(previledges?.full||previledges?.managePrograms)&&<Grid item xs={12} md={6} lg={3}>
              <Card sx={styles.card}>
                <CardContent>
                  <Typography variant="h6">Upcoming Programs</Typography>
                  <Typography variant="h4">{summary.upcomingPrograms}</Typography>
                </CardContent>
              </Card>
            </Grid>}

           {(previledges?.full||previledges?.manageAnnouncements) && <Grid item xs={12} md={6} lg={3}>
              <Card sx={styles.card}>
                <CardContent>
                  <Typography variant="h6">Announcements</Typography>
                  <Typography variant="h4">{summary.announcements}</Typography>
                </CardContent>
              </Card>
            </Grid>}

           {(previledges?.full||previledges?.manageRegistrations) &&<Grid item xs={12} md={6} lg={3}>
              <Card sx={styles.card}>
                <CardContent>
                  <Typography variant="h6">Total Registrations</Typography>
                  <Typography variant="h4">{summary.registrations}</Typography>
                </CardContent>
              </Card>
            </Grid>}

            {previledges?.full&&<Grid item xs={12} md={6} lg={3}>
              <Card sx={styles.card}>
                <CardContent>
                  <Typography variant="h6">Active Admins</Typography>
                  <Typography variant="h4">{summary.admins}</Typography>
                </CardContent>
              </Card>
            </Grid>}

            {previledges?.full&&<Grid item xs={12} md={6} lg={3}>
              <Card sx={styles.card}>
                <CardContent>
                  <Typography variant="h6">Un replied Messages</Typography>
                  <Typography variant="h4">{summary.messages}</Typography>
                </CardContent>
              </Card>
            </Grid>}
            

            {/* Recent Activity */}
            <Grid item xs={12}>
              <Card sx={styles.ActivityCard}>
                <CardContent>
                  <Typography sx={{marginBottom:3}} variant="h6">Recent Activity</Typography>
                  {activities?.loading?
                     <LinearProgress/>
                     :
                     <Box sx={{maxHeight:"50vh",overflow:'auto'}}>
                        {recentActivity.map((activity) => (
                          <Box sx={{marginBottom:2}}>
                            <Typography style={{paddingLeft:10,opacity:0.8}} key={activity.id}>
                               {activity.description}
                            </Typography>
                            <Typography style={{paddingLeft:15,fontSize:11,opacity:0.8,textAlign:"start"}}>{moment(activity.createdAt).format('LLL')}</Typography>
                          </Box>
                        ))}
                     </Box>
                    
                  
                   }
                </CardContent>
              </Card>
            </Grid>

          
          </Grid>
          
        </Box>
      </Box>
    </Box>
  );
}



const styles = {
  container: {
    background: `linear-gradient(-20deg,${Theme.COLOR_THEME_TWO} 0%, ${Theme.COLOR_THEME_ONE} 100%)`,
    minHeight: "90vh",
    padding: "120px 40px 30px 40px",
  },
  content: {
    position: "relative",
    top: "0px",
    width: "100%",
    zIndex: 2,
    color: 'white'
  },
  cards:{
     display:'flex',
     justifyContent:"center",
     alignItems:'center',
  },
  cardsContainer:{
    width:'70%',
    '@media(max-width:800px)':{
      width:"98%"
    }
  },
  card: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '10px',
    color: 'white',
    marginBottom: '20px',
     minHeight: "150px",
     display: "flex",
     flexDirection: "column",
     justifyContent: "center",
     alignItems: "center",
     textAlign:"center"
  },
  ActivityCard:{
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '10px',
    color: 'white',
  },
  welcomeMessage: {
    marginBottom: '30px',
    color:'#b2b8b4'
  },
};

