import {
    FETCH_REGISTRATIONS,
    FETCH_REGISTRATIONS_SUCCESS,
    FETCH_REGISTRATIONS_FAILED,
  
  } from "../store/types";


export const fethRegistrations= () => async (dispatch) => {
    try {
      const authToken = document.cookie
        .split("; ")
        .find((row) => row.startsWith("authToken="))
        ?.split("=")[1];
  
      dispatch({
        type: FETCH_REGISTRATIONS,
        payload: authToken,
      });
  
  
      if (authToken) {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_API}/registration/getAllData`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        const registrations = await response.json();

        dispatch({ type: FETCH_REGISTRATIONS_SUCCESS, payload: registrations });
        
      } else {
        dispatch({
          type: FETCH_REGISTRATIONS_FAILED,
          payload: null ,
        });
      }
    } catch (error) {
      dispatch({ type: FETCH_REGISTRATIONS_FAILED, payload: { error: "Error occured" } });
    }
  };
  

export const addRegistration =(data)=>async(dispatch)=>{
  const authToken = document.cookie
  .split("; ")
  .find((row) => row.startsWith("authToken="))
  ?.split("=")[1];
  
  return new Promise(async (resolve, reject) => {
      try {
           
          if (authToken) {
            const response = await fetch(
              `${process.env.REACT_APP_BACKEND_API}/registration/manual`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${authToken}`,
                },
                body: JSON.stringify(data),
              }
            );
            const Registration = await response.json();
            if(Registration.msg){
               resolve({msg:"Registered Successfully !!"})
            }
            else{
              reject({error:Registration?.error||"Erro occured, try again later"})
            }
            
            
          } else {
            reject({ error: "Un authenticated user" });
          }
        } catch (error) {
          reject({ error: "Error occured, please try again" });
        }
      })


}

export const editRegistrations =(studentData)=>async(dispatch)=>{
  const authToken = document.cookie
  .split("; ")
  .find((row) => row.startsWith("authToken="))
  ?.split("=")[1];
  
  return new Promise(async (resolve, reject) => {
      try {
           
        const {password,assignedClass,studentId} = studentData
          if (authToken) {
            const response = await fetch(
              `${process.env.REACT_APP_BACKEND_API}/registration/edit`,
              {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${authToken}`,
                },
                body: JSON.stringify(studentData),
              }
            );
            const Registration = await response.json();
            if(Registration.msg){
               resolve({msg:"Registration Successfully Updated"})
            }
            else{
              reject({error:Registration?.error||"Erro occured, try again later"})
            }
            
            
          } else {
            reject({ error: "Un authenticated user" });
          }
        } catch (error) {
          reject({ error: "Error occured, please try again" });
        }
      })


}
