import axios from "axios";
import {
    
    FETCH_ANNOUNCEMENTS,
    FETCH_ANNOUNCEMENTS_SUCCESS,
    FETCH_ANNOUNCEMENTS_FAILED,

    CREATE_ANNOUNCEMENTS,
    CREATE_ANNOUNCEMENTS_SUCCESS,
    CREATE_ANNOUNCEMENTS_FAILED,

  } from "../store/types";


export const fethAnnouncements = () => async (dispatch) => {
    try {
      const authToken = document.cookie
        .split("; ")
        .find((row) => row.startsWith("authToken="))
        ?.split("=")[1];
  
      dispatch({
        type: FETCH_ANNOUNCEMENTS,
        payload: authToken,
      });
  
  
      if (authToken) {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_API}/announcements/getAllAnnouncements/admin`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        const announcements = await response.json();
        dispatch({ type: FETCH_ANNOUNCEMENTS_SUCCESS, payload: announcements });
        
      } else {
        dispatch({
          type: FETCH_ANNOUNCEMENTS_FAILED,
          payload: null ,
        });
      }
    } catch (error) {
      dispatch({ type: FETCH_ANNOUNCEMENTS_FAILED, payload: { error: "Error occured" } });
    }
  };
  
export const createAnnouncements = (data) => async (dispatch) => {

    try {
      dispatch({
        type: CREATE_ANNOUNCEMENTS,
        payload: data,
      });
  
      const authToken = document.cookie
      .split("; ")
      .find((row) => row.startsWith("authToken="))
      ?.split("=")[1];
      axios
      .post(`${process.env.REACT_APP_BACKEND_API}/announcements/addAnnouncements`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
             Authorization: `Bearer ${authToken}`,
        },
      })

      .then((response) => {
        dispatch({ type: CREATE_ANNOUNCEMENTS_SUCCESS, payload: response?.data })
        dispatch(fethAnnouncements())
      })

      .catch((error) => {
        console.error(error);
        dispatch({ type: CREATE_ANNOUNCEMENTS_FAILED, payload: error?.response?.data});
      });
      
      
    } catch (error) {
      dispatch({
        type: CREATE_ANNOUNCEMENTS_FAILED,
        payload: { error: "error occured" },
      });
    }
  };


export const updateAnnouncements = (data) => async (dispatch) => {
    try {
      dispatch({
        type: CREATE_ANNOUNCEMENTS,
        payload: data,
      });
  
      const authToken = document.cookie
      .split("; ")
      .find((row) => row.startsWith("authToken="))
      ?.split("=")[1];
      
      axios
      .put(`${process.env.REACT_APP_BACKEND_API}/announcements/updateAnnouncements`, data, {
        headers: {
            "Content-Type": "application/json",
             Authorization: `Bearer ${authToken}`,
        },
      })
  
      .then((response) => {
        dispatch({ type: CREATE_ANNOUNCEMENTS_SUCCESS, payload: response?.data })
        dispatch(fethAnnouncements())
      })
  
      .catch((error) => {
        console.error(error);
        dispatch({ type: CREATE_ANNOUNCEMENTS_FAILED, payload: error?.response?.data});
      });
      
      
    } catch (error) {
      dispatch({
        type: CREATE_ANNOUNCEMENTS_FAILED,
        payload: { error: "error occured" },
      });
    }
  };
  
  export const deleteAnnounecement = (data) => async (dispatch) => {
    try {
      dispatch({
        type: CREATE_ANNOUNCEMENTS,
        payload: data,
      });
  
      const authToken = document.cookie
      .split("; ")
      .find((row) => row.startsWith("authToken="))
      ?.split("=")[1];
  
      axios
      .delete( `${process.env.REACT_APP_BACKEND_API}/announcements/deleteAnnounecement/${data}`, {
        headers: {
            "Content-Type": "application/json",
             Authorization: `Bearer ${authToken}`,
        },
      })
  
      .then((response) => {
        dispatch({ type: CREATE_ANNOUNCEMENTS_SUCCESS, payload: response?.data })
        dispatch(fethAnnouncements())
      })
  
      .catch((error) => {
        console.error(error);
        dispatch({ type: CREATE_ANNOUNCEMENTS_FAILED, payload: error?.response?.data});
      });
      
      
    } catch (error) {
      dispatch({
        type: CREATE_ANNOUNCEMENTS_FAILED,
        payload: { error: "error occured" },
      });
    }
  };
  
