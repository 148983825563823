import {
  Box,
  Typography,
  Button,
  MenuItem,
  ListItemIcon,
  Modal,
  FormControlLabel,
  Switch,
  CircularProgress,
  Grid,
  Divider,
  FormControl,
  Select,
  InputLabel,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import CustomProgrss from "../components/CustomProgress";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";
import CommonSection from "../components/commonSection";
import Header from "../components/Header/Header";
import Theme from "../assets/css/theme";
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
} from "material-react-table";
import { message } from "antd";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomInput from "../components/CustomInput";
import {
  editRegistrations,
  fethRegistrations,
  addRegistration,
} from "../common/action/registrationaction";
import DownloadIcon from '@mui/icons-material/Download';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import "jspdf-autotable";
import moment from "moment/moment";

dayjs.extend(utc);
dayjs.extend(customParseFormat);

export default function Registrations() {
  const dispatch = useDispatch();
  const registration = useSelector((state) => state.registration);


  const [data, setData] = useState([]);
  useEffect(() => {
    if (registration?.registrations) {
      if (registration?.registrations.length === 0) {
        setData([]);
      } else {
        setData(registration?.registrations);
      }
    }
  }, [registration]);
  const [newData, setNewData] = React.useState({
    fullName: "",
    sex: "",
    age: "",
    address: "",
    educationStatus: "",
    previousSchool: "",
    averageResultInPreviousSchool: "",
    behaviorInPreviousSchool: "",
    anyDisease: "",
    assignedClass: "",
    studentId: "",
    fatherName: "",
    fatherPhone: "",
    motherName: "",
    motherPhone: "",
    emergencyResponderName: "",
    emergencyResponderPhone: "",
  });

  const availableGender=[
    {label:"Male",value:"m"},
    {label:"Female",value:"f"}
  ]

  const [errorMessage, setErrorMessage] = message.useMessage();
  const [messageApi, contextHolder] = message.useMessage();
  //successfull message
  const success = (message) => {
    messageApi.open({
      type: "success",
      content: message,
      duration: 5,
    });
  };
  //errror message
  const Error = (message) => {
    errorMessage.open({
      type: "error",
      content: message,
      duration: 5,
    });
  };

  const [openModal, setOpenModal] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleclose = () => {
    setOpenModal(false);
    setOpenEdit(false);
    setNewData({
      fullName: "",
      sex: "",
      age: "",
      address: "",
      educationStatus: "",
      previousSchool: "",
      averageResultInPreviousSchool: "",
      behaviorInPreviousSchool: "",
      anyDisease: "",
      assignedClass: "",
      studentId: "",
      fatherName: "",
      fatherPhone: "",
      motherName: "",
      motherPhone: "",
      emergencyResponderName: "",
      emergencyResponderPhone: "",
    });
  };

  const onEditRow = (row, closeMenu) => {
    const rowData = row?.original;
    setNewData({
      fullName: rowData?.fullName,
      sex: rowData?.sex,
      age: rowData?.age,
      address: rowData?.address,
      educationStatus: rowData?.educationStatus,
      previousSchool: rowData?.previousSchool,
      averageResultInPreviousSchool: rowData?.averageResultInPreviousSchool,
      behaviorInPreviousSchool: rowData?.behaviorInPreviousSchool,
      anyDisease: rowData?.anyDisease,
      assignedClass: rowData?.assignedClass,
      studentId: rowData?.studentId,
      fatherName: rowData?.fatherName,
      fatherPhone: rowData?.fatherPhone,
      motherName: rowData?.motherName,
      motherPhone: rowData?.motherPhone,
      emergencyResponderName: rowData?.emergencyResponderName,
      emergencyResponderPhone: rowData?.emergencyResponderPhone,
      registrationId: rowData?._id,
    });
    setOpenModal(true);
    setOpenEdit(true);
    closeMenu();
  };

  
  
const DownloadPdf = (row, closeMenu) => {
    // Define the HTML content for the data
  const htmlContent = `
  <div style="font-family: Arial, sans-serif; padding: 30px; max-width: 800px; margin: 0 auto; background-color: #f8f9fa;">
    <h1 style="text-align: center; color: #1e2125; font-size: 28px; margin-bottom: 20px;">Registration Data for ${row.original.reference}</h1>
    
    <!-- Personal Information Section -->
    <div style="border: 1px solid #e0e0e0; padding: 20px; border-radius: 8px; background-color: #ffffff; margin-bottom: 20px; box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);">
      <h3 style="color: #007bff; font-size: 20px; margin-bottom: 10px;">Personal Information</h3>
      <p style="margin: 5px 0;"><strong>Full Name:</strong> ${row.original.fullName}</p>
      <p style="margin: 5px 0;"><strong>Gender:</strong> ${row.original.sex === 'f' ? 'Female' : 'Male'}</p>
      <p style="margin: 5px 0;"><strong>Age:</strong> ${row.original.age}</p>
      <p style="margin: 5px 0;"><strong>Student ID:</strong> ${row.original.studentId || "Not Set"}</p>
      <p style="margin: 5px 0;"><strong>Assigned Class:</strong> ${row.original.assignedClass || "Not Set"}</p>
      <p style="margin: 5px 0;"><strong>Registered At:</strong> ${moment(row?.original?.createdAt).format("LLL")}</p>
    </div>

    <!-- Parent/Guardian Information Section -->
    <div style="border: 1px solid #e0e0e0; padding: 20px; border-radius: 8px; background-color: #ffffff; margin-bottom: 20px; box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);">
      <h3 style="color: #007bff; font-size: 20px; margin-bottom: 10px;">Parent/Guardian Information</h3>
      <p style="margin: 5px 0;"><strong>Father's Name:</strong> ${row.original.fatherName}</p>
      <p style="margin: 5px 0;"><strong>Father's Phone:</strong> ${row.original.fatherPhone}</p>
      <p style="margin: 5px 0;"><strong>Mother's Name:</strong> ${row.original.motherName}</p>
      <p style="margin: 5px 0;"><strong>Mother's Phone:</strong> ${row.original.motherPhone}</p>
    </div>

    <!-- Emergency Contact Section -->
    <div style="border: 1px solid #e0e0e0; padding: 20px; border-radius: 8px; background-color: #ffffff; margin-bottom: 20px; box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);">
      <h3 style="color: #007bff; font-size: 20px; margin-bottom: 10px;">Emergency Contact</h3>
      <p style="margin: 5px 0;"><strong>Emergency Responder:</strong> ${row.original.emergencyResponderName}</p>
      <p style="margin: 5px 0;"><strong>Emergency Phone:</strong> ${row.original.emergencyResponderPhone}</p>
      <p style="margin: 5px 0;"><strong>Address:</strong> ${row.original.address}</p>
    </div>

    <!-- Transaction Section (if applicable) -->
    ${row.original.transaction ? `
    <div style="border: 1px solid #e0e0e0; padding: 20px; border-radius: 8px; background-color: #ffffff; margin-bottom: 20px; box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);">
      <h3 style="color: #007bff; font-size: 20px; margin-bottom: 10px;">Transaction Details</h3>
      <p style="margin: 5px 0;"><strong>Transaction Reference:</strong> ${row.original.transaction.tx_ref}</p>
      <p style="margin: 5px 0;"><strong>Amount:</strong> ${row.original.transaction.amount} Birr</p>
    </div>
    ` : ''}

    <!-- Additional Information Section -->
    <div style="border: 1px solid #e0e0e0; padding: 20px; border-radius: 8px; background-color: #ffffff; margin-bottom: 20px; box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);">
      <h3 style="color: #007bff; font-size: 20px; margin-bottom: 10px;">Additional Information</h3>
      <p style="margin: 5px 0;"><strong>Education Status:</strong> ${row.original.educationStatus}</p>
      <p style="margin: 5px 0;"><strong>Previous School:</strong> ${row.original.previousSchool}</p>
      <p style="margin: 5px 0;"><strong>Average Result

      in Previous School:</strong> ${row.original.averageResultInPreviousSchool}</p>
      <p style="margin: 5px 0;"><strong>Behavior in Previous School:</strong> ${row.original.behaviorInPreviousSchool}</p>
      <p style="margin: 5px 0;"><strong>Any Disease:</strong> ${row.original.anyDisease}</p>
    </div>
  </div>
`;
    // Create an element to hold the HTML content
    const element = document.createElement('div');
    element.innerHTML = htmlContent;
    
    // Append the element temporarily to the document to take a snapshot
    document.body.appendChild(element);
  
    // Use html2canvas to capture the HTML content as an image
    html2canvas(element, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('portrait', 'pt', 'a4');
  
      // Calculate image width and height for PDF
      const imgWidth = 595;
      const pageHeight = 842;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
  
      // Add the first page of the PDF
      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
  
      // Add more pages if needed
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
  
      // Save the generated PDF
      pdf.save(`${row.original.fullName}_${row.original.reference}_data.pdf`);
      
      // Remove the temporary HTML element
      document.body.removeChild(element);
  
      // Close the menu after download
      closeMenu();
    });
  };
  



  const validPhoneNumber = (number) => {
    const regex = /^\+251\d{9}$/;
    return regex.test(number);
  };

  const handleSave = () => {
    if (
      !openEdit &&
      (!newData.fullName ||
        !newData.sex ||
        !newData.age ||
        !newData?.address ||
        !newData?.educationStatus ||
        !newData?.previousSchool ||
        !newData?.averageResultInPreviousSchool ||
        !newData?.behaviorInPreviousSchool ||
        !newData?.studentId ||
        !newData?.assignedClass ||
        !newData?.motherName ||
        !newData?.fatherName ||
        !newData?.emergencyResponderName)
    ) {
      Error("Please Fill All Necessery Fields");
      return;
    }
    if (
      !validPhoneNumber(newData?.fatherPhone) ||
      !validPhoneNumber(newData?.motherPhone) ||
      !validPhoneNumber(newData?.emergencyResponderPhone)
    ) {
      Error("Invalid phone, only +251XXXX format is allowed");
      return;
    }


    let dataToBeAdded = { ...newData };
    setLoading(true);
    if (openEdit) {
      dispatch(editRegistrations(dataToBeAdded))
        .then((res) => {
          setLoading(false);
          success(res.msg);
          setTimeout(()=>{
            dispatch(fethRegistrations())
            handleclose()
          },1000)
        })
        .catch((error) => {
          setLoading(false);
          Error(error.error);
        });
    } else {
      dispatch(addRegistration(dataToBeAdded))
        .then((res) => {
          setLoading(false);
          success(res.msg);
          setTimeout(()=>{
            dispatch(fethRegistrations())
            handleclose()
          },1000)
        })
        .catch((error) => {
          setLoading(false);
          Error(error.error);
        });
    }
  };

  const handleDownloadTable = (data) => {
    downloadTablePDF(data); // Pass your table data
  };
  

  const downloadTablePDF = (tableData) => {
    const doc = new jsPDF();
    
    console.log(tableData);
    // Title
    doc.setFontSize(18);
    doc.text("Students Registration Data", 14, 22);
  
    // Table Headers
    const headers = [
      ["Full Name", "Gender", "Age", "Student ID", "Assigned Class",],
    ];
  
    // Table Rows
    const data = tableData.map(row => [
      row.fullName + (row.isManual ? " (Manual)" : ""), 
      row.sex === "f" ? "Female" : "Male",
      row.age,
      row.studentId || "Not Set",
      row.assignedClass || "Not Set",
    ]);
  
    // Generate the table in the PDF
    doc.autoTable({
      head: headers,
      body: data,
      startY: 30,
      theme: "grid",
      styles: { fontSize: 10 },
      headStyles: { fillColor: [71, 71, 135] },
      bodyStyles: { minCellHeight: 9 },
      margin: { top: 10 },
    });
  
    // Save the PDF
    const today=new Date().getTime()
    doc.save(`students_data_${today}.pdf`);
  };

  
  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.fullName}`, //accessorFn used to join multiple data into a single cell
        id: "fullName", //id is still required when using accessorFn instead of accessorKey
        header: "Full Name",
        enableClickToCopy: true,
        size: 250,
        Cell: ({ renderedCellValue, row }) => {
          //console.log(row?.original, `${process.env.REACT_APP_BACKEND_API}/${row.original?.coverPicture}`);
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <Box>{renderedCellValue}   <span style={{color:"#e66a35"}}>{row?.original?.isManual?"(Manual)":""}</span></Box>
            </Box>
          );
        },
      },
      {
        accessorFn: (row) => `${row?.sex}`, //accessorFn used to join multiple data into a single cell
        id: "sex", //id is still required when using accessorFn instead of accessorKey
        header: "Gender",
        size: 70,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Box>{renderedCellValue === "f" ? "Female" : "Male"}</Box>
          </Box>
        ),
      },
      {
        accessorFn: (row) => `${row?.age}`, //accessorFn used to join multiple data into a single cell
        id: "age", //id is still required when using accessorFn instead of accessorKey
        header: "Age",
        size: 70,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Box>{renderedCellValue}</Box>
          </Box>
        ),
      },
      {
        accessorFn: (row) => `${row.studentId}`, //accessorFn used to join multiple data into a single cell
        id: "studentId", //id is still required when using accessorFn instead of accessorKey
        header: "Student Id",
        enableClickToCopy: true,
        size: 250,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Box>{row?.original?.studentId || "Not Set"}</Box>
          </Box>
        ),
      },
      {
        accessorFn: (row) => `${row.assignedClass}`, //accessorFn used to join multiple data into a single cell
        id: "assignedClass", //id is still required when using accessorFn instead of accessorKey
        header: "Assigned Class",
        size: 250,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Box>{row?.original?.assignedClass || "Not Set"}</Box>
          </Box>
        ),
      },
      {
        accessorFn: (row) => `${row.createdAt}`, //accessorFn used to join multiple data into a single cell
        id: "createdAt", //id is still required when using accessorFn instead of accessorKey
        header: "Registered At" ,
        size: 250,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Box>{moment(row?.original?.createdAt).format("LLL")}</Box>
          </Box>
        ),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableColumnFilterModes: true,
    enableColumnOrdering: true,
    enableGrouping: true,
    enableColumnPinning: true,
    enableFacetedValues: true,
    enableRowActions: true,
    initialState: {
      showColumnFilters: false,
      showGlobalFilter: true,
      columnPinning: {
        left: ["mrt-row-expand", "mrt-row-select"],
        right: ["mrt-row-actions"],
      },
    },
    mrtTheme: (theme) => ({
      baseBackgroundColor: "rgba(255, 255, 255, 0.01)", // Light background for overall table
    }),
    muiTableHeadCellProps: {
      sx: {
        background: `linear-gradient(135deg, #9e9d9e 0%, #f4f0f5 100%)`, // Vibrant gradient for header
        color: "black", // White text for contrast
        fontWeight: "bold", // Make the header text bold
        fontSize: "1rem", // Slightly larger font for visibility
      },
    },
    muiTableBodyCellProps: {
      sx: {
        background: "rgba(255, 255, 255, 0.05)", // Subtle white overlay for table body
        color: "#d3d3d3", // Light grey for text
        borderBottom: "1px solid rgba(255, 255, 255, 0.1)", // Faint border for rows
        "&:hover": {
          backgroundColor: "rgba(255, 255, 255, 0.1)", // Slight hover effect
        },
      },
    },
    muiTableBodyRowProps: {
      sx: {
        "&:nth-of-type(odd)": {
          backgroundColor: "rgba(0, 0, 0, 0.05)", // Slight background change for alternating rows
        },
      },
    },
    muiPaginationProps: {
      color: "secondary", // Secondary theme color for pagination controls
      rowsPerPageOptions: [10, 20, 30],
      shape: "rounded",
      variant: "outlined",
      sx: {
        background: "rgba(0, 0, 0, 0.1)", // Slight background to make pagination stand out
      },
    },
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
      color: "secondary", // Secondary color for the search field
      sx: {
        background: "rgba(255, 255, 255, 0.6)", // Transparent background to blend with the table
      },
    },
    renderDetailPanel: ({ row }) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          backgroundColor: "#2A2A2A", // Dark background for contrast
          borderRadius: "10px",
          padding: "20px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Adds depth
          maxWidth: "1000px",
          left: '30px',
          position: 'sticky',
          width: '100%',
          color:"white",
        }}
        
      >
        {/* Title */}
        <Typography
          variant="h6"
          sx={{
            marginBottom: "16px",
            fontWeight: "bold",
            color: "#E0E0E0",
            borderBottom: "2px solid #7B61FF",
            paddingBottom: "8px",
            width: "100%",
            textAlign: "center",
          }}
        >
          Registration Data of {row?.original?.reference}
        </Typography>

        {/* Information Container */}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box sx={{ marginBottom: "8px" }}>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                Father’s Name:
              </Typography>
              <Typography variant="body2" sx={{ opacity: 0.8 }}>
                {row?.original?.fatherName}
              </Typography>
            </Box>

            <Box sx={{ marginBottom: "8px" }}>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                Father's Phone:
              </Typography>
              <Typography variant="body2" sx={{ opacity: 0.8 }}>
                {row?.original?.fatherPhone}
              </Typography>
            </Box>

            <Box sx={{ marginBottom: "8px" }}>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                Mother’s Name:
              </Typography>
              <Typography variant="body2" sx={{ opacity: 0.8 }}>
                {row?.original?.motherName}
              </Typography>
            </Box>

            <Box sx={{ marginBottom: "8px" }}>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                Mother's Phone:
              </Typography>
              <Typography variant="body2" sx={{ opacity: 0.8 }}>
                {row?.original?.motherPhone}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box sx={{ marginBottom: "8px" }}>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                Emergency Responder:
              </Typography>
              <Typography variant="body2" sx={{ opacity: 0.8 }}>
                {row?.original?.emergencyResponderName}
              </Typography>
            </Box>

            <Box sx={{ marginBottom: "8px" }}>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                Emergency Phone:
              </Typography>
              <Typography variant="body2" sx={{ opacity: 0.8 }}>
                {row?.original?.emergencyResponderPhone}
              </Typography>
            </Box>

            <Box sx={{ marginBottom: "8px" }}>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                Address:
              </Typography>
              <Typography variant="body2" sx={{ opacity: 0.8 }}>
                {row?.original?.address}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* Divider for Transaction Details */}
        <Divider sx={{ marginY: "16px", backgroundColor: "#7B61FF" }} />

        {/* Transaction Details */}
        <Box sx={{ marginBottom: "8px" }}>
          <Typography variant="body1" sx={{ fontWeight: 500 }}>
            Transaction:
          </Typography>
          <Typography variant="body2" sx={{ opacity: 0.8 }}>
            {row?.original?.transaction?.tx_ref} -{" "}
            {row?.original?.transaction?.amount} Birr
          </Typography>
        </Box>

        {/* Additional Details */}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box sx={{ marginBottom: "8px" }}>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                Education Status:
              </Typography>
              <Typography variant="body2" sx={{ opacity: 0.8 }}>
                {row?.original?.educationStatus}
              </Typography>
            </Box>

            <Box sx={{ marginBottom: "8px" }}>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                Previous School:
              </Typography>
              <Typography variant="body2" sx={{ opacity: 0.8 }}>
                {row?.original?.previousSchool}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box sx={{ marginBottom: "8px" }}>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                Average Result in Previous School:
              </Typography>
              <Typography variant="body2" sx={{ opacity: 0.8 }}>
                {row?.original?.averageResultInPreviousSchool}
              </Typography>
            </Box>

            <Box sx={{ marginBottom: "8px" }}>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                Behavior in Previous School:
              </Typography>
              <Typography variant="body2" sx={{ opacity: 0.8 }}>
                {row?.original?.behaviorInPreviousSchool}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* Final Section */}
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1" sx={{ fontWeight: 500 }}>
            Any Disease:
          </Typography>
          <Typography variant="body2" sx={{ opacity: 0.8 }}>
            {row?.original?.anyDisease}
          </Typography>
        </Box>
      </Box>
    ),
    renderRowActionMenuItems: ({ row, closeMenu }) => [
      <MenuItem key={0} onClick={() => onEditRow(row, closeMenu)} sx={{ m: 0 }}>
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
      </MenuItem>,
      <MenuItem key={0} onClick={() => DownloadPdf(row, closeMenu)} sx={{ m: 0 }}>
      <ListItemIcon>
        <DownloadIcon />
      </ListItemIcon>
    </MenuItem>,

      // <MenuItem
      //   key={1}
      //   onClick={() => onDeleteRow(row, closeMenu)}
      //   sx={{ m: 0 }}
      // >
      //   <ListItemIcon>
      //     <DeleteIcon style={{ color: "red" }} />
      //   </ListItemIcon>
      // </MenuItem>,
    ],
    renderTopToolbar: ({ table }) => {
      const handleDeactivate = () => {
        setOpenModal(true);
      };
      return (
        <Box
          sx={(theme) => ({
            display: "flex",
            gap: "0.5rem",
            p: "8px",
            justifyContent: "space-between",
          })}
        >
          <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
            {/* import MRT sub-components */}
            <MRT_GlobalFilterTextField table={table} />
            <MRT_ToggleFiltersButton table={table} />
          </Box>
          <Box sx={{ display: "flex", gap: "0.5rem" }}>
            <Button
              sx={sxStyles.button}
              onClick={handleDeactivate}
              variant="contained"
            >
              Add
            </Button>
            {data?.length>0&&<Button
              onClick={()=>handleDownloadTable(data)}
              variant="contained"
              sx={{textTransform:"inherit",}}
            >
              Download Table
            </Button>}
          </Box>
        </Box>
      );
    },
  });

  const onInputChange = (event) => {
    setNewData({ ...newData, [event.target.id]: event.target.value });
  };

  const handleChange = (event) => {
    setNewData({...newData,sex:event.target.value});
  };

  return (
    <Box sx={sxStyles.container}>
      <CommonSection />
      <Header />
      <Box sx={sxStyles.content}>
        <Typography variant="h4" sx={sxStyles.typography}>
          Registrations{" "}
        </Typography>
        {registration.loading ? 
              <Box sx={sxStyles.progressIndicator}>
                    <CircularProgress size={60}/>
              </Box>
             :
          <Box sx={{marginTop:3}}>
            {setErrorMessage}
            {contextHolder}
            <MaterialReactTable table={table} />
          </Box>
        }
      </Box>
      <Modal
        open={openModal}
        onClose={handleclose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={sxStyles.modal}>
          <Typography
            sx={{
              textAlign: "center",
              marginBottom: 5,
              fontSize: 20,
              fontWeight: 700,
            }}
          >
            {!openEdit ? "Registration Form" : "Registration Form"}
          </Typography>
          <Box sx={sxStyles.eachInput}>
            <CustomInput
              id="fullName"
              labelText="Full Name"
              labelColor="rgba(255, 255, 255, 0.7)"
              value={newData.fullName}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "text",
              }}
              color="white"
              onChange={onInputChange}
              placeholder="Enter Full Name"
            />
          </Box>
          <Box  sx={sxStyles.eachInput}>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: '100%', }}>
                    <InputLabel style={{color:"rgba(255, 255, 255, 0.7)"}} id="demo-simple-select-standard-label">Gender</InputLabel>
                    {(openEdit &&false) ?
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={newData.sex}
                          onChange={handleChange}
                          label="Gender"
                          style={{color:"white"}}
                          disabled={false}
                        >
                          
                          <MenuItem value={newData.sex}>{newData.sex}</MenuItem>
                          
                        </Select>
                        :
                        <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={newData.sex}
                        onChange={handleChange}
                        label="Class Type"
                        style={{color:"white"}}
                      >
                        
                        {availableGender.map(e=>{
                          return <MenuItem key={e.value} value={e.value}>{e.label}</MenuItem>
                          })
                        }
                        
                      </Select>
                    
                  }
              </FormControl>
         </Box>


          <Box sx={sxStyles.eachInput}>
            <CustomInput
              id="age"
              labelText="Age"
              labelColor="rgba(255, 255, 255, 0.7)"
              value={newData.age}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "number",
              }}
              disabled={false}
              color="white"
              onChange={onInputChange}
              placeholder="Enter Age"
            />
          </Box>

          <Box sx={sxStyles.eachInput}>
            <CustomInput
              id="address"
              labelText="Address"
              labelColor="rgba(255, 255, 255, 0.7)"
              value={newData.address}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "text",
              }}
              disabled={false}
              color="white"
              onChange={onInputChange}
              placeholder="Enter Address"
            />
          </Box>

          <Box sx={sxStyles.eachInput}>
            <CustomInput
              id="educationStatus"
              labelText="Education Status"
              labelColor="rgba(255, 255, 255, 0.7)"
              value={newData.educationStatus}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "text",
              }}
              disabled={false}
              color="white"
              onChange={onInputChange}
              placeholder="Enter Education Status"
            />
          </Box>

          <Box sx={sxStyles.eachInput}>
            <CustomInput
              id="previousSchool"
              labelText="Previous School"
              labelColor="rgba(255, 255, 255, 0.7)"
              value={newData.previousSchool}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "text",
              }}
              disabled={false}
              color="white"
              onChange={onInputChange}
              placeholder="Enter Previous School"
            />
          </Box>

          <Box sx={sxStyles.eachInput}>
            <CustomInput
              id="averageResultInPreviousSchool"
              labelText="Average Result (Previous School)"
              labelColor="rgba(255, 255, 255, 0.7)"
              value={newData.averageResultInPreviousSchool}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "text",
              }}
              disabled={false}
              color="white"
              onChange={onInputChange}
              placeholder="Enter Average Result"
            />
          </Box>

          <Box sx={sxStyles.eachInput}>
            <CustomInput
              id="behaviorInPreviousSchool"
              labelText="Behavior  (Previous School)"
              labelColor="rgba(255, 255, 255, 0.7)"
              value={newData.behaviorInPreviousSchool}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "text",
              }}
              disabled={false}
              color="white"
              onChange={onInputChange}
              placeholder="Enter Behavior"
            />
          </Box>

          <Box sx={sxStyles.eachInput}>
            <CustomInput
              id="anyDisease"
              labelText="Any Disease"
              labelColor="rgba(255, 255, 255, 0.7)"
              value={newData.anyDisease}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "text",
              }}
              disabled={false}
              color="white"
              onChange={onInputChange}
              placeholder="Enter Health Details"
            />
          </Box>

          <Box sx={sxStyles.eachInput}>
            <CustomInput
              id="assignedClass"
              labelText="Assigned Class"
              labelColor="rgba(255, 255, 255, 0.7)"
              value={newData.assignedClass}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "text",
              }}
              disabled={false}
              color="white"
              onChange={onInputChange}
              placeholder="Enter Assigned Class"
            />
          </Box>

          <Box sx={sxStyles.eachInput}>
            <CustomInput
              id="studentId"
              labelText="Student ID"
              labelColor="rgba(255, 255, 255, 0.7)"
              value={newData.studentId}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "text",
              }}
              disabled={false}
              color="white"
              onChange={onInputChange}
              placeholder="Enter Student ID"
            />
          </Box>

          <Box sx={sxStyles.eachInput}>
            <CustomInput
              id="fatherName"
              labelText="Father's Name"
              labelColor="rgba(255, 255, 255, 0.7)"
              value={newData.fatherName}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "text",
              }}
              disabled={false}
              color="white"
              onChange={onInputChange}
              placeholder="Enter Father's Name"
            />
          </Box>

          <Box sx={sxStyles.eachInput}>
            <CustomInput
              id="fatherPhone"
              labelText="Father's Phone"
              labelColor="rgba(255, 255, 255, 0.7)"
              value={newData.fatherPhone}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "text",
              }}
              disabled={false}
              color="white"
              onChange={onInputChange}
              placeholder="Enter Father's Phone"
            />
          </Box>

          <Box sx={sxStyles.eachInput}>
            <CustomInput
              id="motherName"
              labelText="Mother's Name"
              labelColor="rgba(255, 255, 255, 0.7)"
              value={newData.motherName}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "text",
              }}
              disabled={false}
              color="white"
              onChange={onInputChange}
              placeholder="Enter Mother's Name"
            />
          </Box>

          <Box sx={sxStyles.eachInput}>
            <CustomInput
              id="motherPhone"
              labelText="Mother's Phone"
              labelColor="rgba(255, 255, 255, 0.7)"
              value={newData.motherPhone}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "text",
              }}
              disabled={false}
              color="white"
              onChange={onInputChange}
              placeholder="Enter Mother's Phone"
            />
          </Box>

          <Box sx={sxStyles.eachInput}>
            <CustomInput
              id="emergencyResponderName"
              labelText="Emergency Responder Name"
              labelColor="rgba(255, 255, 255, 0.7)"
              value={newData.emergencyResponderName}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "text",
              }}
              disabled={false}
              color="white"
              onChange={onInputChange}
              placeholder="Enter Emergency Responder Name"
            />
          </Box>

          <Box sx={sxStyles.eachInput}>
            <CustomInput
              id="emergencyResponderPhone"
              labelText="Emergency Responder Phone"
              labelColor="rgba(255, 255, 255, 0.7)"
              value={newData.emergencyResponderPhone}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "text",
              }}
              disabled={false}
              color="white"
              onChange={onInputChange}
              placeholder="Enter Emergency Responder Phone"
            />
          </Box>

          <Box
            sx={{
              textAlign: "center",
              marginTop: "10px",
              marginBottom: 3,
              width: "100%",
            }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <Button onClick={handleSave} sx={{...sxStyles?.button,...sxStyles.eachInput}}>
                Save
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

const sxStyles = {
  container: {
    background: `linear-gradient(-20deg,${Theme.COLOR_THEME_TWO} 0%, ${Theme.COLOR_THEME_ONE} 100%)`,
    minHeight: "90vh",
    padding: "120px 40px 30px 40px",
  },
  progressIndicator:{
    height:"50vh",
    display:'flex',
    justifyContent:"center",
    alignItems:'center',
},
  content: {
    position: "relative",
    top: "0px",
    width: "100%",
    zIndex: 2,
    color: "white",
  },
  typography: {
    padding: "10px 0 0 20px",
  },
  button: {
    backgroundColor: "#371238",
    color: "white",
    textTransform: "inherit",
    "&:hover": {
      background: "#371238",
      //background: "#00bab4",
    },
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    bgcolor: "rgba(62, 64, 62)",
    border: "2px solid #000",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    color: "white",
    zIndex: 10,
    height: "60vh",
    overflow: "auto",
    color: "white",
    "::-webkit-scrollbar": {
      width: "1px",
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "@media(max-width:800px)": {
      width: "80%",
    },
  },
  eachInput: {
    marginBottom: 2,
    width: "60%",
    "@media(max-width:800px)": {
      width: "80%",
    },
  },
};
