import { Box, Typography,Button, TextField, Snackbar, Alert, CircularProgress, FormControlLabel, Switch, InputAdornment,
  
} from '@mui/material'
;
import React,{ useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from "react-redux";
import CustomProgrss from '../components/CustomProgress';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import CommonSection from '../components/commonSection';
import Header from '../components/Header/Header';
import Theme from '../assets/css/theme';
import { borderRadius, display, width } from '@mui/system';
import { UpdateAdmins } from '../common/action/adminsAction';
import { fetchUser } from '../common/action/authAction';
import { UpdateSettings, fetchSettings } from '../common/action/settingaction';

dayjs.extend(utc);
dayjs.extend(customParseFormat);


export default function Setting({previledges}) {
const dispatch = useDispatch()
const auth = useSelector(state=>state.auth)
const setting = useSelector(state=>state.setting)


const [data, setData] = useState({
  phone: '',
  email: '',
  password: '',
  confirmPassword: ''
});
const [passwordError, setPasswordError] = useState('');

useEffect(() => {
  if (auth.info) {
    setData({
      phone: auth.info?.phone || '',
      email: auth.info?.admin?.email || '',
      password: '',
      confirmPassword: ''
    });
  }
}, [auth]);

const handleChange = (e) => {
  const { name, value } = e.target;

  setData({
    ...data,
    [name]: value
  });

  // Check if passwords match
  if (name === 'password' || name === 'confirmPassword') {
    if (name === 'confirmPassword' && value !== data.password) {
      setPasswordError('Passwords do not match');
    } else if (name === 'password' && value !== data.confirmPassword) {
      setPasswordError('Passwords do not match');
    } else {
      setPasswordError('');
    }
  }
};

const[error,setError] = useState("")
const [loading,setLoading] = useState(false)

const isValidEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
}

const [open, setOpen] = React.useState({flag:false,msg:null,type:'error'});
  const handleClick = (message,type) => {
    setOpen({flag:true,msg:message,type:type});
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

const handleSubmit = () => {
  if (passwordError) {
    handleClick("Please Confirm password","error")
    return;
  }
  if(!isValidEmail(data.email)){
    handleClick("Invalid email","error")
    return
  }

  setLoading(true)
  dispatch(UpdateAdmins(data))
    .then(res=>{
       handleClick(res?.msg,"success")
       setLoading(false)
       dispatch(fetchUser());
    })
    .catch(error=>{
       handleClick(error?.error,"error")
       setLoading(false)
    })


};


const [settings, setSettings] = useState({
  maintenanceMode: setting?.setting?.maintenanceMode || false,
  maxRegistrationsMale: setting?.setting?.maxRegistrationsMale || '',
  maxRegistrationsFemale: setting?.setting?.maxRegistrationsFemale || '',
  registrationStartDate: setting?.setting?.registrationStartDate || '',
  registrationEndDate: setting?.setting?.registrationEndDate || '',
  registrationPaymentAmount: setting?.setting?.registrationPaymentAmount || '',
  registrationAccountNumber: setting?.setting?.registrationAccountNumber || '',
  registrationPaymentReceiver: setting?.setting?.registrationPaymentReceiver || ''
  

});

useEffect(()=>{
      if(setting?.setting){
        setSettings(
          {
            maintenanceMode: setting?.setting?.maintenanceMode || false,
            maxRegistrationsMale: setting?.setting?.maxRegistrationsMale || '',
            maxRegistrationsFemale: setting?.setting?.maxRegistrationsFemale || '',
            registrationStartDate: setting?.setting?.registrationStartDate || '',
            registrationEndDate: setting?.setting?.registrationEndDate || '',
            registrationPaymentAmount: setting?.setting?.registrationPaymentAmount || '',
            registrationAccountNumber: setting?.setting?.registrationAccountNumber || '',
            registrationPaymentReceiver: setting?.setting?.registrationPaymentReceiver || ''
            
          }

        )
      } 
},[setting])

const handleSettingChange= (e) => {
  setSettings({
    ...settings,
    [e.target.name]: e.target.value
  });
};

const handleSwitchChange = (e) => {
  setSettings({
    ...settings,
    maintenanceMode: e.target.checked
  });
};

const [settingLoading,setSettingLoading] = useState(false)
const handleUpdateSetting = () => {
  if(!settings?.registrationStartDate || !settings?.registrationPaymentReceiver ||!settings?.registrationAccountNumber ||!settings?.registrationPaymentAmount || !settings?.maxRegistrationsFemale || !settings?.maxRegistrationsMale ||!settings?.registrationEndDate){
    handleClick("Please fill necessery datas","error")
    return;
  }
  setSettingLoading(true)


  dispatch(UpdateSettings(settings))
    .then(res=>{
       handleClick(res?.msg,"success")
       setSettingLoading(false)
       dispatch(fetchSettings());
    })
    .catch(error=>{
       handleClick(error?.error,"error")
       setSettingLoading(false)
    })

  handleClose();
};



return auth.loading ? (
 <CustomProgrss />
) : (
 <Box sx={sxStyles.container}>
     <CommonSection />
     <Header />
     <Box sx={sxStyles.content}>
       <Typography variant='h4' sx={sxStyles.typography}>Settings</Typography>
        <Box sx={sxStyles?.boxContainer}>
          <Snackbar open={open.flag} autoHideDuration={5000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity={open?.type}
              variant="filled"
              sx={{ width: '100%' }}
            >
               {open?.msg}
            </Alert>
          </Snackbar>

          <Box sx={sxStyles.profileHolder}>
              {/* <TextField
                fullWidth
                label="Phone"
                name="phone"
                value={data.phone}
                onChange={handleChange}
                sx={{ mb: 2 }}
              /> */}
              <Typography variant="h6" sx={{ mb: 3,color:"black",fontWeight:700 }}>Profile Settings</Typography>
              <TextField
                fullWidth
                label="Email"
                name="email"
                value={data.email}
                onChange={handleChange}
                sx={{ mb: 2 }}
                type='email'
              />
              
              <TextField
                fullWidth
                label="Password"
                name="password"
                type="password"
                value={data.password}
                onChange={handleChange}
                sx={{ mb: 2 }}
              />
              
              <TextField
                fullWidth
                label="Confirm Password"
                name="confirmPassword"
                type="password"
                value={data.confirmPassword}
                onChange={handleChange}
                error={!!passwordError}
                sx={{ mb: 2 }}
              />
              
              {
                loading?
                  <CircularProgress/>
                :
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={!!passwordError}
                  onClick={handleSubmit}
                >
                  Update Profile
                </Button>
              }
          </Box>

          {previledges?.full&&<Box sx={sxStyles.profileHolder}>
              <Typography variant="h6" sx={{ mb: 3,color:"black",fontWeight:700  }}>Registration Settings</Typography>

                {setting?.loading?
                      <CircularProgress/>
                 :
                   <Box sx={{display:"flex",flexDirection:"column",gap:3}}>
                   {setting?.error&&<Box sx={{color:"red"}}>{setting?.error}</Box>}
                      {/* Maintenance Mode Switch */}
                      <FormControlLabel
                        control={
                          <Switch
                            checked={settings.maintenanceMode}
                            onChange={handleSwitchChange}
                          />
                        }
                        label="Maintenance Mode"
                        sx={{color:'black',marginBottom:2}}
                      />

                      {/* Input fields */}
                      <TextField
                        fullWidth
                        label="Max Registrations (Male)"
                        name="maxRegistrationsMale"
                        value={settings.maxRegistrationsMale}
                        onChange={handleSettingChange}
                        sx={{ mb: 2 }}
                        type="number"
                      />

                      <TextField
                        fullWidth
                        label="Max Registrations (Female)"
                        name="maxRegistrationsFemale"
                        value={settings.maxRegistrationsFemale}
                        onChange={handleSettingChange}
                        sx={{ mb: 2 }}
                        type="number"
                      />

                      <TextField
                        fullWidth
                        label="Registration Start Date"
                        name="registrationStartDate"
                        value={settings.registrationStartDate}
                        onChange={handleSettingChange}
                        sx={{ mb: 2 }}
                        type="datetime-local"
                        InputLabelProps={{ shrink: true }}
                      />

                      <TextField
                        fullWidth
                        label="Registration End Date"
                        name="registrationEndDate"
                        value={settings.registrationEndDate}
                        onChange={handleSettingChange}
                        sx={{ mb: 2 }}
                        type="datetime-local"
                        InputLabelProps={{ shrink: true }}
                      />

                      <TextField
                        fullWidth
                        label="Registration Payment Amount"
                        name="registrationPaymentAmount"
                        value={settings.registrationPaymentAmount}
                        onChange={handleSettingChange}
                        sx={{ mb: 2 }}
                        type="number"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <span>Birr</span>
                            </InputAdornment>
                          ),
                        }}
                        helperText="Please set the payment for the registration. "
                      />

                    <TextField
                        fullWidth
                        label="CBE Account Number for Payments"
                        name="registrationAccountNumber"
                        value={settings.registrationAccountNumber}
                        onChange={handleSettingChange}
                        sx={{ mb: 2 }}
                        type="number"
                        helperText="Enter the Commercial Bank of Ethiopia account number to receive payments. Please provide the correct CBE account number for payment processing."
                       
                      />

                     <TextField
                        fullWidth
                        label="Account Owner's Name"
                        name="registrationPaymentReceiver"
                        value={settings.registrationPaymentReceiver}
                        onChange={handleSettingChange}
                        sx={{ mb: 2 }}
                        helperText="Please provide the correct name of the account owner to ensure payments are correctly processed."
                       
                      />




                      {/* Submit button */}
                      {
                        settingLoading?
                          <CircularProgress />
                        :
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          onClick={handleUpdateSetting}
                        >
                          Save Settings
                        </Button>

                      }
                   </Box>
                  }

          </Box>}

        </Box>

     </Box>
 </Box>
)
}



const sxStyles={
container:{
 background: `linear-gradient(-20deg,${Theme.COLOR_THEME_TWO} 0%, ${Theme.COLOR_THEME_ONE} 100%)`,
 minHeight: "90vh",
 padding: "120px 40px 30px 40px",
},
content: {
 position: "relative",
 top: "0px",
 width: "100%",
 zIndex: 2,
 color: 'white'
},
typography:{
 padding:"10px 0 0 20px"
}, 
boxContainer:{
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
      flexDirection:"column",
      gap:7,
      marginTop:"40px"
},
profileHolder:{
  maxWidth:"600px",
  width:'60%',
  background:"white",
  padding:10,
  paddingTop:4,
  borderRadius:10,
 
}
}