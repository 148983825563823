export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILED = "FETCH_USER_FAILED";

export const USER_SIGN_UP = "USER_SIGN_UP";
export const USER_SIGN_UP_FAILED = "USER_SIGN_UP_FAILED";
export const USER_SIGN_UP_SUCCESS="USER_SIGN_UP_SUCCESS"

export const FETCH_ANNOUNCEMENTS = "FETCH_ANNOUNCEMENTS";
export const FETCH_ANNOUNCEMENTS_SUCCESS = "FETCH_ANNOUNCEMENTS_SUCCESS";
export const FETCH_ANNOUNCEMENTS_FAILED="FETCH_ANNOUNCEMENTS_FAILED"

export const CREATE_ANNOUNCEMENTS = "CREATE_ANNOUNCEMENTS";
export const CREATE_ANNOUNCEMENTS_SUCCESS = "CREATE_ANNOUNCEMENTS_SUCCESS";
export const CREATE_ANNOUNCEMENTS_FAILED="CREATE_ANNOUNCEMENTS_FAILED"

export const FETCH_PROGRAMS = "FETCH_PROGRAMS";
export const FETCH_PROGRAMS_FAILED = "FETCH_PROGRAMS_FAILED";
export const FETCH_PROGRAMS_SUCCESS="FETCH_PROGRAMS_SUCCESS"

export const FETCH_MESSAGES = "FETCH_MESSAGES";
export const FETCH_MESSAGES_SUCCESS = "FETCH_MESSAGES_SUCCESS";
export const FETCH_MESSAGES_FAILED="FETCH_MESSAGES_FAILED"

export const FETCH_REGISTRATIONS = "FETCH_REGISTRATIONS";
export const FETCH_REGISTRATIONS_FAILED = "FETCH_REGISTRATIONS_FAILED";
export const FETCH_REGISTRATIONS_SUCCESS="FETCH_REGISTRATIONS_SUCCESS"

export const CREATE_PROGRAMS = "CREATE_PROGRAMS";
export const CREATE_PROGRAMS_SUCCESS = "CREATE_PROGRAMS_SUCCESS";
export const CREATE_PROGRAMS_FAILED="CREATE_PROGRAMS_FAILED"

export const FETCH_ACTIVITIES = "FETCH_ACTIVITIES";
export const FETCH_ACTIVITIES_SUCCESS = "FETCH_ACTIVITIES_SUCCESS";
export const FETCH_ACTIVITIES_FAILED="FETCH_ACTIVITIES_FAILED"

export const FETCH_ADMINS = "FETCH_ADMINS";
export const FETCH_ADMINS_SUCCESS = "FETCH_ADMINS_SUCCESS";
export const FETCH_ADMINS_FAILED = "FETCH_ADMINS_FAILED"

export const FETCH_SETTING = "FETCH_SETTING";
export const FETCH_SETTING_SUCCESS = "FETCH_SETTING_SUCCESS";
export const FETCH_SETTING_FAILED = "FETCH_SETTING_FAILED"



export const SIGN_IN = "SIGN_IN";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_FAILED = "SIGN_IN_FAILED";


export const USER_SIGN_OUT = "USER_SIGN_OUT";
export const CLEAR_LOGIN_ERROR = "CLEAR_LOGIN_ERROR";

export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const UPDATE_USER_PROFILE_FAILED = "UPDATE_USER_PROFILE_FAILED";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const CLEAR_USER_PROFILE_UPDATE_STATUS = "CLEAR_USER_PROFILE_UPDATE_STATUS";

export const USER_NOT_REGISTERED = "USER_NOT_REGISTERED";
export const USER_DELETED = "USER_DELETED";


