import {
    FETCH_MESSAGES,
    FETCH_MESSAGES_SUCCESS,
    FETCH_MESSAGES_FAILED,
  
  } from "../store/types";


export const fethMessages = () => async (dispatch) => {
    try {
      const authToken = document.cookie
        .split("; ")
        .find((row) => row.startsWith("authToken="))
        ?.split("=")[1];
  
      dispatch({
        type: FETCH_MESSAGES,
        payload: authToken,
      });
  
  
      if (authToken) {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_API}/message/getAll`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        const messages = await response.json();
        dispatch({ type: FETCH_MESSAGES_SUCCESS, payload: messages });
        
      } else {
        dispatch({
          type: FETCH_MESSAGES_FAILED,
          payload: null ,
        });
      }
    } catch (error) {
      dispatch({ type: FETCH_MESSAGES_FAILED, payload: { error: "Error occured" } });
    }
  };
  

export const replyMessage =(adminData)=>async(dispatch)=>{
  const authToken = document.cookie
  .split("; ")
  .find((row) => row.startsWith("authToken="))
  ?.split("=")[1];
  
  return new Promise(async (resolve, reject) => {
      try {
           
        const {adminMessage,messageId} = adminData
          if (authToken) {
            const response = await fetch(
              `${process.env.REACT_APP_BACKEND_API}/message/reply`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${authToken}`,
                },
                body: JSON.stringify({
                    message:adminMessage,
                    messageId         
                }),
              }
            );
            const messageSent = await response.json();
            if(messageSent.msg){
               resolve({msg:"Message Successfully Sent"})
            }
            else{
              reject({error:messageSent?.error||"Erro occured, try again later"})
            }
            
            
          } else {
            reject({ error: "Un authenticated user" });
          }
        } catch (error) {
          reject({ error: "Error occured, please try again" });
        }
      })


}
