import { authreducer as auth } from "../reducer/authReducer";
import { ProgramsReducer as programs } from "../reducer/programreducer";
import { activityReducer as activities } from "../reducer/activityReducer";
import { AdminReducer as admins } from "../reducer/adminReducer";
import { AnnouncementReducer as announcements } from "../reducer/announcementReducer";
import { settingReducer as setting } from "../reducer/settingreducer";
import { registrationReducer as registration } from "../reducer/registrationreducer";
import { messageReducer as messages } from "../reducer/messageReducer";
import { createStore, combineReducers, applyMiddleware } from "redux";
import {thunk} from 'redux-thunk';

const reducers = combineReducers({
    auth,
    programs,
    activities,
    admins,
    announcements,
    setting,
    registration,
    messages
})

let middleware = [thunk];

export default createStore(reducers, {}, applyMiddleware(...middleware));