import {
    FETCH_SETTING,
    FETCH_SETTING_SUCCESS,
    FETCH_SETTING_FAILED,
  
  } from "../store/types";

  export const UpdateSettings =(settingData)=>async(dispatch)=>{

    const authToken = document.cookie
    .split("; ")
    .find((row) => row.startsWith("authToken="))
    ?.split("=")[1];
    
    return new Promise(async (resolve, reject) => {
        try {
             
  
            if (authToken) {
              const response = await fetch(
                `${process.env.REACT_APP_BACKEND_API}/user/editSetting`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authToken}`,
                  },
                  body: JSON.stringify(settingData),
                }
              );
              const admin = await response.json();
              if(admin.msg){
                 resolve({msg:admin?.msg})
              }
              else{
                reject({error:admin?.error||"Erro occured, try again later"})
              }
              
              
            } else {
              reject({ error: "Un authenticated user" });
            }
          } catch (error) {
            reject({ error: "Error occured, please try again" });
          }
        })

  }
  export const fetchSettings =()=>async(dispatch)=>{
    try {
     
  
      dispatch({
        type: FETCH_SETTING,
        payload: null,
      });
  
  
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_API}/user/setting`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const setting = await response.json();
        if(setting?.error){
            dispatch({
                type: FETCH_SETTING_FAILED,
                payload: setting?.error ,
              });
        }
        else dispatch({ type: FETCH_SETTING_SUCCESS, payload: setting });
        
      
      
    } catch (error) {
      dispatch({ type: FETCH_SETTING_FAILED, payload: { error: "Error occured" } });
    }
  }