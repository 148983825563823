import {
  Box,
  Typography,
  Button,
  MenuItem,
  ListItemIcon,
  Modal,
  FormControlLabel,
  Switch,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import CustomProgrss from "../components/CustomProgress";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";
import CommonSection from "../components/commonSection";
import Header from "../components/Header/Header";
import Theme from "../assets/css/theme";
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
} from "material-react-table";
import { message } from "antd";
import ReplyIcon from '@mui/icons-material/Reply';
import CustomInput from "../components/CustomInput";
import {
  addAdmin,
  deleteAdmin,
  editAdmin,
  fethAdmins,
} from "../common/action/adminsAction";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { fethMessages, replyMessage } from "../common/action/meessageActions";
import { width } from "@mui/system";

dayjs.extend(utc);
dayjs.extend(customParseFormat);

export default function Admins() {
  const dispatch = useDispatch();
  const messages = useSelector((state) => state.messages);

  const [data, setData] = useState([]);
  useEffect(() => {
    if (messages?.messages) {
      if (messages?.messages.length === 0) {
        setData([]);
      } else {
        setData(messages?.messages);
      }
    }
  }, [messages]);

  
  const [newData, setNewData] = React.useState({
        email: "",
        clientMessage: "",
        adminMessage: "",      
  });

  const [errorMessage, setErrorMessage] = message.useMessage();
  const [messageApi, contextHolder] = message.useMessage();
  //successfull message
  const success = (message) => {
    messageApi.open({
      type: "success",
      content: message,
      duration: 5,
    });
  };
  //errror message
  const Error = (message) => {
    errorMessage.open({
      type: "error",
      content: message,
      duration: 5,
    });
  };

  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleclose = () => {
    setOpenModal(false);
    setNewData({
      email: "",
      clientMessage: "",
      adminMessage: "",
     
    });
  };

  const onReply = (row, closeMenu) => {
    //  if( row.original?.isReplied){
    //     Error("You have already replied this message.  You can't reply multiple times");
    //     return
    //  }
    setNewData({
      email: row.original?.email,
      clientMessage: row.original.message,
      messageId: row.original?._id,
      adminMessage:""
    });
    setOpenModal(true);
    closeMenu();
  };


  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const validPhoneNumber = (number) => {
    const regex = /^\+251\d{9}$/;
    return regex.test(number);
  };


  const handleSave = () => {
    if (!newData?.adminMessage) {
      Error("Message is required");
      return;
    }
  
    setLoading(true);
    dispatch(replyMessage(newData))
        .then((res) => {
          setLoading(false);
          success(res.msg);
          setTimeout(() => {
            dispatch(fethMessages());
            handleclose();
          }, 1000);
        })
        .catch((error) => {
          setLoading(false);
          Error(error.error);
        });
   
  };


  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row?.fullName}`, //accessorFn used to join multiple data into a single cell
        id: "fullName", //id is still required when using accessorFn instead of accessorKey
        header: "Full Name",
        enableClickToCopy: true,
        size: 250,
        Cell: ({ renderedCellValue, row }) => {
          //console.log(row?.original, `${process.env.REACT_APP_BACKEND_API}/${row.original?.coverPicture}`);
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <Box >{renderedCellValue} <span style={{color:"#c95c30"}}>{!row?.original?.isReplied?"Not Replied":""}</span></Box>
            </Box>
          );
        },
      },
      {
        accessorFn: (row) => `${row?.email}`, //accessorFn used to join multiple data into a single cell
        id: "email", //id is still required when using accessorFn instead of accessorKey
        header: "Email",
        enableClickToCopy: true,
        size: 250,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Box >{renderedCellValue}</Box>
          </Box>
        ),
      },
      {
        accessorFn: (row) => `${row?.phone}`, //accessorFn used to join multiple data into a single cell
        id: "phone", //id is still required when using accessorFn instead of accessorKey
        header: "Phone",
        enableClickToCopy: true,
        size: 250,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Box>{renderedCellValue}</Box>
          </Box>
        ),
      },
      {
        accessorFn: (row) => `${row?.message}`, //accessorFn used to join multiple data into a single cell
        id: "message", //id is still required when using accessorFn instead of accessorKey
        header: "Message",
        enableClickToCopy: true,
        size: 250,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Box sx={{lineBreak:"anywhere",whiteSpace:"break-spaces"}}>{renderedCellValue}</Box>
          </Box>
        ),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableColumnFilterModes: true,
    enableColumnOrdering: true,
    enableGrouping: true,
    enableColumnPinning: true,
    enableFacetedValues: true,
    enableRowActions: true,
    initialState: {
      showColumnFilters: false,
      showGlobalFilter: true,
      columnPinning: {
        left: ["mrt-row-expand", "mrt-row-select"],
        right: ["mrt-row-actions"],
      },
    },
    mrtTheme: (theme) => ({
      baseBackgroundColor: "rgba(255, 255, 255, 0.01)", // Light background for overall table
    }),
    muiTableHeadCellProps: {
      sx: {
        background: `linear-gradient(135deg, #9e9d9e 0%, #f4f0f5 100%)`, // Vibrant gradient for header
        color: "black", // White text for contrast
        fontWeight: "bold", // Make the header text bold
        fontSize: "1rem", // Slightly larger font for visibility
      },
    },
    muiTableBodyCellProps: {
      sx: {
        background: "rgba(255, 255, 255, 0.05)", // Subtle white overlay for table body
        color: "#d3d3d3", // Light grey for text
        borderBottom: "1px solid rgba(255, 255, 255, 0.1)", // Faint border for rows
        "&:hover": {
          backgroundColor: "rgba(255, 255, 255, 0.1)", // Slight hover effect
        },
      },
    },
    muiTableBodyRowProps: {
      sx: {
        "&:nth-of-type(odd)": {
          backgroundColor: "rgba(0, 0, 0, 0.05)", // Slight background change for alternating rows
        },
      },
    },
    muiPaginationProps: {
      color: "secondary", // Secondary theme color for pagination controls
      rowsPerPageOptions: [10, 20, 30],
      shape: "rounded",
      variant: "outlined",
      sx: {
        background: "rgba(0, 0, 0, 0.1)", // Slight background to make pagination stand out
      },
    },
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
      color: "secondary", // Secondary color for the search field
      sx: {
        background: "rgba(255, 255, 255, 0.6)", // Transparent background to blend with the table
      },
    },
    renderDetailPanel: ({ row }) => (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          left: "30px",
          maxWidth: "1000px",
          position: "sticky",
          width: "100%",
          color: "white",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h4"
          sx={{ mb: 3, color: "white", fontWeight: 700 }}
        >
          Replied Message
        </Typography>
        <Box sx={{lineBreak:"anywhere",whiteSpace:"break-spaces"}}>{row.original?.repliedMessage}</Box>
      </Box>
    ),
    renderRowActionMenuItems: ({ row, closeMenu }) => [
      <MenuItem key={0} onClick={() => onReply(row, closeMenu)} sx={{ m: 0 }}>
        <ListItemIcon>
          <ReplyIcon />
        </ListItemIcon>
      </MenuItem>,
    ],
    renderTopToolbar: ({ table }) => {
      const handleDeactivate = () => {
        setOpenModal(true);
      };
      return (
        <Box
          sx={(theme) => ({
            display: "flex",
            gap: "0.5rem",
            p: "8px",
            justifyContent: "space-between",
          })}
        >
          <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
            {/* import MRT sub-components */}
            <MRT_GlobalFilterTextField table={table} />
            <MRT_ToggleFiltersButton table={table} />
          </Box>
        </Box>
      );
    },
  });

  const onInputChange = (event) => {
    setNewData({ ...newData, [event.target.id]: event.target.value });
  };

  return (
    <Box sx={sxStyles.container}>
      <CommonSection />
      <Header />
      <Box sx={sxStyles.content}>
        <Typography variant="h4" sx={sxStyles.typography}>
          Messages{" "}
        </Typography>
        {messages.loading ? (
          <Box sx={sxStyles.progressIndicator}>
            <CircularProgress size={60} />
          </Box>
        ) : (
          <Box sx={{ marginTop: 3 }}>
            {setErrorMessage}
            {contextHolder}
            <MaterialReactTable table={table} />
          </Box>
        )}
      </Box>
      <Modal
        open={openModal}
        onClose={handleclose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={sxStyles.modal}>
          <Typography
            sx={{
              textAlign: "center",
              marginBottom: 5,
              fontSize: 20,
              fontWeight: 700,
            }}
          >
            Reply message to {newData?.email}
          </Typography>
          <Box sx={{marginBottom:7,...sxStyles.eachInput}}>
             <Typography sx={{fontWeight:600,opacity:0.8}}>Client Message</Typography>
             <Box sx={{ paddingLeft: 5,marginTop:1 ,background: "#616266", borderRadius: "8px", padding: "10px" }}>
               {newData?.clientMessage}
              </Box>
          </Box>
          <Box sx={sxStyles.eachInput}>
            <CustomInput
              id="adminMessage"
              labelText="Click here to reply message"
              labelColor="rgba(255, 255, 255, 0.7)"
              value={newData.adminMessage}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "text",
              }}
              color="white"
              onChange={onInputChange}
              placeholder="Message"
              multiline={true}
              rows={3}
            />
          </Box>
          <Box
            sx={{
              textAlign: "center",
              marginTop: "10px",
              marginBottom: 3,
              width: "100%",
            }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                onClick={handleSave}
                sx={{ ...sxStyles?.button, ...sxStyles.eachInput }}
              >
                Send
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

const sxStyles = {
  container: {
    background: `linear-gradient(-20deg,${Theme.COLOR_THEME_TWO} 0%, ${Theme.COLOR_THEME_ONE} 100%)`,
    minHeight: "90vh",
    padding: "120px 40px 30px 40px",
  },
  progressIndicator: {
    height: "50vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    position: "relative",
    top: "0px",
    width: "100%",
    zIndex: 2,
    color: "white",
  },
  typography: {
    padding: "10px 0 0 20px",
  },
  button: {
    backgroundColor: "#371238",
    color: "white",
    textTransform: "inherit",
    "&:hover": {
      background: "#371238",
      //background: "#00bab4",
    },
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    bgcolor: "rgba(62, 64, 62)",
    border: "2px solid #000",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    color: "white",
    zIndex: 10,
    height: "60vh",
    overflow: "auto",
    color: "white",
    "::-webkit-scrollbar": {
      width: "1px",
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "@media(max-width:800px)": {
      width: "80%",
    },
  },
  eachInput: {
    marginBottom: 2,
    width: "60%",
    "@media(max-width:800px)": {
      width: "80%",
    },
  },
};
