import {
    FETCH_SETTING,
    FETCH_SETTING_SUCCESS,
    FETCH_SETTING_FAILED,
  
  } from "../store/types";
  
  const INITIAL_STATE = {
    info: null,
    loading: true,
    error: null,
    setting: null,
  };
  
  export const settingReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_SETTING:
        return {
          ...state,
          loading: true,
        };
      case FETCH_SETTING_SUCCESS:
        return {
          ...state,
          setting: action.payload,
          loading: false,
          error: null,
          info: null,
        };
      case FETCH_SETTING_FAILED:
        return {
          ...state,
          setting: null,
          info: null,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  