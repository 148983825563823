import React, { useEffect, useState } from "react";
import { Box, IconButton, Typography, useTheme, useMediaQuery, Drawer, List, ListItem, ListItemText } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import logo from "../../assets/img/newLogo.svg";
import { height } from "@mui/system";

export default function Header() {
  const isMobile = useMediaQuery("(max-width: 980px)");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const auth = useSelector((state) => state.auth);

  const [privileges, setPrivileges] = useState({ full: false });

  useEffect(() => {
    if (auth?.info?.admin?.adminType === 'full') {
      setPrivileges({ full: true });
    } else {
      setPrivileges({ ...auth?.info?.admin?.halfAdminPrivileges });
    }
  }, [auth]);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const renderLinks = () => (
    <>
      {(privileges?.full || privileges?.managePrograms) && (
        <NavLink
          to="/programs"
          style={({ isActive }) => (isActive ? styles.activeLink : styles.link)}
        >
           Programs
        </NavLink>
      )}
      {(privileges?.full || privileges?.manageAnnouncements) && (
        <NavLink
          to="/announcement"
          style={({ isActive }) => (isActive ? styles.activeLink : styles.link)}
        >
           Announcements
        </NavLink>
      )}
      {(privileges?.full || privileges?.manageRegistrations) && (
        <NavLink
          to="/registrations"
          style={({ isActive }) => (isActive ? styles.activeLink : styles.link)}
        >
            Registrations
        </NavLink>
      )}
      {privileges?.full && (
        <NavLink
          to="/admins"
          style={({ isActive }) => (isActive ? styles.activeLink : styles.link)}
        >
           Admins
        </NavLink>
      )}
      {privileges?.full && (
        <NavLink
          to="/messages"
          style={({ isActive }) => (isActive ? styles.activeLink : styles.link)}
        >
           Messages
        </NavLink>
      )}
      <NavLink
        to="/setting"
        style={({ isActive }) => (isActive ? styles.activeLink : styles.link)}
      >
         Setting
      </NavLink>
    </>
  );

  return (
    <Box sx={styles.header}>
      <Box>
        <NavLink to="/" style={{ color: "white", textDecoration: "none", fontSize: 23 }}>
          Zad Al-Mead
        </NavLink>
      </Box>

      {isMobile ? (
        <>
          <IconButton onClick={toggleDrawer(true)} sx={styles.hamburgerIcon}>
            <MenuIcon sx={{ color: "white", fontSize: 30 }} />
          </IconButton>
          <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
            <Box sx={styles.drawer}>
              <Box><img src={logo} width={100}/></Box>
              <List  sx={styles.drawerList} >
                {renderLinks().props.children.map((link, index) => (
                  <ListItem button key={index} onClick={toggleDrawer(false)}>
                    <ListItemText>{link}</ListItemText>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Drawer>
        </>
      ) : (
        <Box sx={styles.navigations}>
          {renderLinks()}
        </Box>
      )}
    </Box>
  );
}

const styles = {
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: 'white',
    width: "95%",
    position: "absolute",
    top: 0,
    left: 0,
    background: "transparent",
    height: "fit-content",
    zIndex: 10,
    marginTop: 3,
    padding:'0 10px 0 30px',
    '@media(max-width:700px)':{
      width:"90%"
    }
  },
  textColor: {
    color: "white",
  },
  navigations: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    gap: 3,
  },
  link: {
    textDecoration: "none",
    color: "white",
    padding: "0 10px",

  },
  activeLink: {
    textDecoration: "none",
    fontWeight: "bold",
    padding: "0 10px",
    color: "#8d608f",
  },
  hamburgerIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  drawer: {
    width: 250,
    padding: "10px 20px",
    backgroundColor: 'rgba(33, 38, 34)',
    height:"100vh",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection:"column",
    paddingTop:10
  },
  drawerList:{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection:"column",
    minHeight:"40vh"
  }
};
